import React, { useState, useEffect } from "react";
import { UserResume } from "../../UserResume/UserResume";
import "./AvailabilityResume.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import imageUrls from "../../../../assets/profileAvatarUrls";

const Availability = ({
  userData,
  setUserData,
  onsubmitForm,
  currentProfileData,
}) => {
  const [openToWork, setIsOpenToWork] = useState(false);
  const [workTime, setWorkTime] = useState("");
  const [canRelocate, setCanRelocate] = useState(false);
  const [workType, setWorkType] = useState("");
  const [location, setLocation] = useState("");
  const [salary, setSalary] = useState("");
  const [hasChanges, setHasChanges] = useState(false);
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [avatar, setAvatar] = useState("");

  console.log("the current shit is ", canRelocate)

  useEffect(() => {
    setIsOpenToWork(currentProfileData?.workDetails?.openToWork);
    setWorkTime(currentProfileData?.workDetails?.workTime);
    setCanRelocate(currentProfileData?.workDetails?.canRelocate || false);
    setWorkType(currentProfileData?.workDetails?.workType);
    setLocation(currentProfileData?.workDetails?.location);
    setSalary(currentProfileData?.workDetails?.salary);
    setYearsOfExperience(currentProfileData?.workDetails?.yearsOfExperience);
    setAvatar(currentProfileData?.avatar)
  }, [currentProfileData]);


  useEffect(() => {
    const currentData = {
      openToWork,
      workTime,
      canRelocate,
      workType,
      location,
      salary,
      yearsOfExperience,
    };

    const isChanged =
      JSON.stringify(currentData) !==
      JSON.stringify(currentProfileData?.workDetails);
    setHasChanges(isChanged);
  }, [
    openToWork,
    workTime,
    canRelocate,
    workType,
    location,
    salary,
    yearsOfExperience,

    currentProfileData,
  ]);

  const OnSubmit = (event) => {
    event.preventDefault();
    console.log("the can relocate is ", canRelocate)
    const updatedUserProfile = {
      ...userData,
      avatar: avatar,
      workDetails: {
        openToWork,
        workTime,
        canRelocate : canRelocate,
        workType,
        location,
        salary,
        yearsOfExperience,
      },
    };

    console.log("the work details ", updatedUserProfile?.workDetails)

    onsubmitForm(updatedUserProfile);
  };

  const resetToPrevData = (event) => {
    event.preventDefault();
    setUserData(currentProfileData);
    setIsOpenToWork(currentProfileData?.workDetails?.openToWork);
    setWorkTime(currentProfileData?.workDetails?.workTime);
    setCanRelocate(currentProfileData?.workDetails?.canRelocate);
    setWorkType(currentProfileData?.workDetails?.workType);
    setLocation(currentProfileData?.workDetails?.location);
    setSalary(currentProfileData?.workDetails?.salary);
    setYearsOfExperience(currentProfileData?.workDetails?.yearsOfExperience);
    setAvatar(currentProfileData?.avatar);
  };


  const toggleOpenToWork = () => {
    setIsOpenToWork(!openToWork);
  };

  const handleWorkTimeChange = (e) => {
    setWorkTime(e.target.value);
  };

  const toggleCanRelocate = () => {
    setCanRelocate(!canRelocate);
  };

  const handleWorkTypeChange = (e) => {
    setWorkType(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleSalaryChange = (e) => {
    setSalary(e.target.value);
  };

  const handleYearsOfExperience = (e) => {
    setYearsOfExperience(e.target.value);
  };

 
  

  return (
    <main className="availability_container">
      <form
        className="AvailabilityAndResume"
        onSubmit={OnSubmit}
      >
        <div className="Fields">
          <div className="additionalField">
            <div className="radioButtonBox">
              <p className="fieldHeading">Open To Work*</p>
              <div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="openToWorkToggle"
                    name="openToWorkGroup"
                    checked={openToWork}
                    onChange={toggleOpenToWork}
                  />
                  <p className={openToWork ? "selectedText" : ""}>
                    {" "}
                    Yes, I’m an active job seeker
                  </p>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="openToWorkToggle"
                    name="openToWorkGroup"
                    checked={!openToWork}
                    onChange={toggleOpenToWork}
                  />
                  <p className={!openToWork ? "selectedText" : ""}>
                    {" "}
                    No, I’m just exploring opportunities passively
                  </p>
                </div>
              </div>
            </div>
            <div className="inputFieldBox">
              <label htmlFor="location">Location:</label>
              <input
                type="text"
                id="location"
                value={location}
                placeholder="Your current location"
                onChange={handleLocationChange}
              />
            </div>
            <div className="radioButtonBox">
              <p className="fieldHeading">Work Time*</p>
              <div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimeFullTime"
                    name="workTimeGroup"
                    value="full-time"
                    checked={workTime === "full-time"}
                    onChange={handleWorkTimeChange}
                  />
                  <p className={workTime === "full-time" ? "selectedText" : ""}>
                    {" "}
                    Full Time{" "}
                  </p>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimePartTime"
                    name="workTimeGroup"
                    value="part-time"
                    checked={workTime === "part-time"}
                    onChange={handleWorkTimeChange}
                  />
                  <p className={workTime === "part-time" ? "selectedText" : ""}>
                    {" "}
                    Part Time
                  </p>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimeContract"
                    name="workTimeGroup"
                    value="contract"
                    checked={workTime === "contract"}
                    onChange={handleWorkTimeChange}
                  />
                  <p className={workTime === "contract" ? "selectedText" : ""}>
                    {" "}
                    Contract
                  </p>
                </div>

                <div className="toggleButton">
                  <input
                    type="radio"
                    id="workTimeContract"
                    name="workTimeGroup"
                    value="freelance"
                    checked={workTime === "freelance"}
                    onChange={handleWorkTimeChange}
                  />
                  <p className={workTime === "freelance" ? "selectedText" : ""}>
                    {" "}
                    Freelance
                  </p>
                </div>
              </div>
            </div>
            <div className="radioButtonBox">
              <p className="fieldHeading">Can Relocate*</p>
              <div className="toggleButton">
                <input
                  type="radio"
                  id="canRelocateTrue"
                  name="canRelocatedGroup"
                  checked={canRelocate}
                  onChange={toggleCanRelocate}
                />
                <p className={canRelocate ? "selectedText" : ""}> Yes </p>
              </div>
              <div className="toggleButton">
                <input
                  type="radio"
                  id="canRelocateFalse"
                  name="canRelocatedGroup"
                  checked={!canRelocate}
                  onChange={toggleCanRelocate}
                />
                <p className={!canRelocate ? "selectedText" : ""}> No </p>
              </div>
            </div>
            <div className="radioButtonBox">
              <p className="fieldHeading">Work Type*</p>

              <div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="remote"
                    name="workType"
                    value="remote"
                    checked={workType === "remote"}
                    onChange={handleWorkTypeChange}
                  />
                  <p className={workType === "remote" ? "selectedText" : ""}>
                    Remote
                  </p>
                </div>
                <div className="toggleButton">
                  <input
                    type="radio"
                    id="on-site"
                    name="workType"
                    value="on-site"
                    checked={workType === "on-site"}
                    onChange={handleWorkTypeChange}
                  />
                  <p className={workTime === "on-site" ? "selectedText" : ""}>
                    On-Site
                  </p>
                </div>
              </div>
            </div>

            <div className="inputFieldBox">
              <label htmlFor="salary">Salary:</label>
              <input
                type="text"
                id="salary"
                value={salary}
                placeholder="Expected salary"
                onChange={handleSalaryChange}
              />
            </div>
            <div className="inputFieldBox">
              <label htmlFor="yearsofexperience">Years of experience</label>
              <input
                type="text"
                id="yearsofexperience"
                value={yearsOfExperience}
                placeholder="Years of experience"
                onChange={handleYearsOfExperience}
              />
            </div>
          </div>
          <div className="additionalField extraField">
            <div className="InfoDiv">
              <p className="fieldHeading">Resume</p>
              <UserResume resume={userData?.resume} />
            </div>
          </div>
          <div className="additionalField extraField">
              <p className="fieldHeading">Choose your avatar</p>
          </div>
        </div>

 
      </form>
      <div className="profileAvatarList">
        {imageUrls.map((image, index) => (

          <div className="singleAvatarComponent">
            <img
              src={image}
              alt={`avatar-${index}`}
              className={`singleAvatar ${avatar === image ? "selectedAvatar" : ""}`}
              key={index}
              onClick={() => setAvatar(image)}
            />
          </div>
        ))}
      </div>
      <div className="newFormButtonContainer">
      <div className="newFormButtons">
          <TwoButtonWrapper
            firstComponent={"Cancel"}
            backgroundColor={"white"}
            color={"black"}
            padding={"5px 20px"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={(e) => resetToPrevData(e)}
          ></TwoButtonWrapper>
          <TwoButtonWrapper
            firstComponent={"Save"}
            padding={"5px 20px"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={OnSubmit}
          ></TwoButtonWrapper>
        </div>
      </div>

      {/* <div className="formButtons">
        <div className="innerFormContainer">
          <button className="formBtn" onClick={(e) => resetToPrevData(e)}>
            Cancel
          </button>
          <button
            disabled={!hasChanges}
            onClick={OnSubmit}
            type="submit"
            className="formBtn"
          >
            Save
          </button>
        </div>
      </div> */}
    </main>
  );
};

export default Availability;
