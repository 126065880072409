import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import ProfileImage from "../img/profile.png";
import { Link } from "react-router-dom";

const Sidebar = ({
  query,
  Fields,
  Marketersdata,
  setBreadCrumbNextRoute,
}) => {
  const [userRoute, setUserRoute] = useState(
    window.location.pathname.split("/")[2]
  );
  const [isDisabled, setIsDisabled] = useState(query ? true : false);
  const [menuOpen, setMenuOpen] = useState(false); // State to handle menu open/close
  const [currentTab, setCurrentTab] = useState(
    userRoute ? userRoute : "profile"
  ); // Added back currentTab useState

  useEffect(() => {
    if (query) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [query]);

  const toggleMenu = () => {
    setMenuOpen((prevState) => !prevState); // Toggle the menu state
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.UserSection')) {
      setMenuOpen(false); // Close menu if clicked outside
    }
  };

  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('click', handleOutsideClick);
    } else {
      document.removeEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [menuOpen]);

  if (userRoute === undefined) {
    setUserRoute("credits");
  }

  const fieldStyle = {
    fontWeight: "700",
    fontSize: "18px",
    BorderTop: "none",
  };

  const handleFieldStyle = (event) => {
    const { name, value } = event.target;
    if (query) {
      setIsDisabled(true);
      return;
    }
    setBreadCrumbNextRoute(value);
    setCurrentTab(name); // Correctly use setCurrentTab here
    if (event.target.name === "new-company-profiles") {
      localStorage.setItem(
        "profile",
        JSON.stringify({ profile: { profileId: "" } })
      );
    }
  };

  return (
    <>
      {/* Main sidebar container */}
      <div className={`UserSection ${menuOpen ? "active" : "compact"}`}>
        {/* Mobile menu icon (burger button) */}
        <div className="mobileMenuIcon" onClick={toggleMenu}>
          <img src="https://unispade-s3.blr1.cdn.digitaloceanspaces.com/hamburger-menu-5.svg" alt="Menu" />
        </div>

        {/* Profile Section */}
        <div className="userProfile">
          <img
            className="profile_image"
            src={Marketersdata?.profilePhoto || ProfileImage}
            alt="profile_image"
          />
          <div className="UserProfileInfo">
            <p>{Marketersdata?.name}</p>
            <p>{Marketersdata?.username}</p>
          </div>
        </div>

        {/* Fields Section */}
        <div className={`Fields ${menuOpen ? "active" : ""}`}>
          {Fields.map((key) => {
            return (
              key.name !== "new-company-profiles" && (
                <Link
                  key={key.name}
                  to={"/user-dashboard/" + key.name}
                  className="sideBarAnchorTags"
                >
                  <button
                    style={key.name === currentTab ? fieldStyle : {}} // Use currentTab for styling
                    name={key.name}
                    value={key.text}
                    className={`sideBarButtons`}
                    disabled={key.disable || isDisabled}
                    onClick={(e) => handleFieldStyle(e)}
                  >
                    {key.text}
                  </button>
                </Link>
              )
            );
          })}
        </div>
      </div>

      {/* Overlay for closing menu when clicking outside */}
      {menuOpen && <div className="overlay active" onClick={toggleMenu}></div>}
    </>
  );
};

export default Sidebar;
