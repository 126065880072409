import React, { useState,useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "../../axios"
import Loader from "../loader/Loader";
import TopComp from "../HomePageComponents/TopComp/topComp"
import TalentPoolComp from "../HomePageComponents/TalentPoolComp/talentPoolComp"
import MiddleComp from "../HomePageComponents/MiddleComp/middleComp"
import BottomComp from "../HomePageComponents/BottomComp/bottomComp"
import "./HomePage.css"

const HomePage = () => {
  return (
    <>
      <div className="homePage">
        <TopComp/>
        <TalentPoolComp/>
        <MiddleComp/>
        <BottomComp/>
      </div> 
      <Helmet>
        <link rel="canonical" href="https://www.unispade.com/" />
        <meta
          name="description"
          content="Find the talent endorsed by industry experts"
        />
        <title>
        Unispade | Talent Recommendation Platform
        </title>
      </Helmet>
    </>
  );
};

export default HomePage;
