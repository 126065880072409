import React, { useState } from "react";
import "../../src/styles/ResumeStyle.css";
import imageLink from "./UserProfileCompo/img/award3.png";
import cancelIcon from "./UserProfileCompo/img/close_40dp_FILL0_wght400_GRAD0_opsz24.svg";
import wavingIcon from "./UserProfileCompo/img/waving_hand_48dp_FILL0_wght400_GRAD0_opsz48.svg";
import addIcon from "./UserProfileCompo/img/add_48dp_FILL0_wght400_GRAD0_opsz48 (1).svg";
import reviewsIcon from "./MarketerDashbCompo/Talent/TalentPool/demoImages/reviews.svg";
import { IoIosAddCircleOutline, IoMdArrowDropdown } from "react-icons/io";

import member1 from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/member1.svg";
import member2 from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/member2.svg";
import member3 from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/member3.svg";
import member4 from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/member4.svg";

import freelance from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/account.svg";
import fullTime from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/account-group.svg";
import remote from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/wifi.svg";
import relocate from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/airplane.svg";
import mapMarker from "./UserProfileCompo/UserPageContent.js/DemoImages/Individual Profile/map-marker.svg";

const ResumeForm = ({
  talentPoolUser,
  previewUserId,
  handleClosePopover,
  setWaveHandFunctionParams,
  setWavehandFunction,
  handleSelectMessageIndividual,
}) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const calculateYearsOfExperience = (fromYear, tillYear) => {
    const from = new Date(`01 January ${fromYear}`);
    const till = new Date(`01 January ${tillYear}`);
    const differenceInYears = till.getFullYear() - from.getFullYear();

    return differenceInYears;
  };

  const checkSingleUserAvailability = () => {
    setWaveHandFunctionParams();
    setWavehandFunction();
  };

  const selectSingleUser = () => {
    handleSelectMessageIndividual();
    handleClosePopover(previewUserId);
  };
  console.log("the talent pool user is", talentPoolUser);
  return (
    <>
      {talentPoolUser?.experience && (
        <div id="informationBox" className="PreviewPopup">
          <div className="popup-content">
            <div className="popup-title">
              {true && (
                <div className="currentMemberInfo">
                  <div className="currentMemberCancel">
                    <div className="currentMemberNameInfo">
                      <div className="resume-image-container">
                        <>
                          <img
                            className="resume-image"
                            src={talentPoolUser?.user_reference_data?.avatar}
                            alt="my_pic"
                          />
                        </>
                      </div>
                      <div className="referenceId">
                        {talentPoolUser?.user_reference_data?.ref_id}
                      </div>
                    </div>
                    <div className="closeButton">
                      <button
                        className="close-button"
                        onClick={() => handleClosePopover(previewUserId)}
                      >
                        <img
                          src={cancelIcon}
                          alt="cancelIcon"
                          className="close-button-img"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="membersTagsAndSkills">
                    <div className="providedByMentor">
                      <div className="theHeadingLine">
                        <div className="circleWithTitle">
                          <div
                            style={{
                              width: "18px",
                              height: "18px",
                              backgroundColor: "black",
                              borderRadius: "50%",
                              border: "1px solid black",
                            }}
                          ></div>

                          <div>Skills Verified by mentor</div>
                        </div>
                        <div className="circleWithTitle">
                          <div
                            style={{
                              width: "18px",
                              height: "18px",
                              backgroundColor: "white",
                              borderRadius: "50%",
                              border: "1px solid black",
                            }}
                          ></div>

                          <div>Tags Verified by mentor</div>
                        </div>
                      </div>
                      <div className="contentContainer">
                        {talentPoolUser?.skills?.map((skill) => {
                          return (
                            <div className="singleMentorSkill">
                              {skill?.name}
                            </div>
                          );
                        })}

                        {talentPoolUser?.tags?.map((tag) => {
                          return (
                            <div className="singleMentorTag">{tag?.name}</div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="providedByUser">
                      <div className="theHeadingLine">
                        <div className="circleWithTitle">
                          <div
                            style={{
                              width: "18px",
                              height: "18px",
                              backgroundColor: "#E0E0E0",
                              borderRadius: "50%",
                              border: "1px solid #E0E0E0",
                            }}
                          ></div>

                          <div>Skills provided by candidates</div>
                        </div>
                      </div>
                      <div className="contentContainer">
                        {talentPoolUser?.user_assigned_skills?.map((skill) => {
                          return <div className="singleUserSkill">{skill}</div>;
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="currentMemberOtherInfo">
                    {talentPoolUser?.workDetails?.location && (
                      <div className="currentMemberSingleOtherInfo">
                        <img src={mapMarker} alt="" />
                        <span>{talentPoolUser?.workDetails?.location}</span>
                      </div>
                    )}

                    <div className="currentMemberSingleOtherInfo">
                      <img
                        src={
                          !talentPoolUser?.workDetails?.canRelocate
                            ? remote
                            : relocate
                        }
                        alt=""
                      />
                      <span>
                        {!talentPoolUser?.workDetails?.canRelocate
                          ? "Remote"
                          : "Willing to relocate"}
                      </span>
                    </div>

                    {talentPoolUser?.workDetails?.workType && (
                      <div className="currentMemberSingleOtherInfo">
                        <span>{talentPoolUser?.workDetails?.workType}</span>
                      </div>
                    )}

                    {talentPoolUser?.workDetails?.workTime && (
                      <div className="currentMemberSingleOtherInfo">
                        <span class="material-symbols-outlined">hourglass</span>
                        {talentPoolUser?.workDetails?.workTime}
                      </div>
                    )}

                    {talentPoolUser?.workDetails?.yearsOfExperience && (
                      <div className="currentMemberSingleOtherInfo">
                        <span className="material-symbols-outlined">
                          business_center
                        </span>
                        <span>
                          {talentPoolUser?.workDetails?.yearsOfExperience} years
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            {talentPoolUser?.rejectionComment && (
              <div className="userCommentComponent">
                <div className="commentTitle">
                  <img src={reviewsIcon} alt="" />
                  <span
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Comments
                  </span>
                </div>
                <div className="commentBody">
                  {talentPoolUser?.rejectionComment}
                </div>
              </div>
            )}

            {talentPoolUser?.experience?.map((experience) => (
              <div className="experience-item" key={experience?.id}>
                {" "}
                <div className="companyLogoContainer">
                  <img
                    src={experience?.companyLogo}
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      display: "inline",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="writtenExperience">
                  <div className="experience-item-header">
                    <div className="experience-item-title">
                      {experience?.role}
                    </div>
                    <div className="experience-item-company">
                      {experience?.companyName}
                    </div>
                    <div className="experience-item-duration">
                    {experience?.dates?.from?.month}{" "}
                    {experience?.dates?.from?.year} -{" "}
                    {experience?.currently_working
                      ? "Present"
                      : experience?.dates?.till?.month +
                        " " +
                        experience?.dates?.till?.year}
                    </div>
                  </div>
                  <div className="experience-item-description">
                    <p>{experience?.description}</p>
                  </div>
                </div>
              </div>
            ))}
            <div className="experience-item-button">
              <button
                className="check-availability-button"
                onClick={() => checkSingleUserAvailability()}
              >
                <img src={wavingIcon} alt="waving hand" width="23px" />
                Check availability
              </button>
              <button
                className="select-profile-button"
                onClick={() => selectSingleUser()}
              >
                <img src={addIcon} alt="addicon" width="23px" />
                Select Profile
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ResumeForm;
