// Code to generealize the avatar images
// const imageNumbers = Array.from({ length: 100 }, (_, i) => i + 1);
// const imageUrls = imageNumbers.map(number => `https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-${number}.png`);



const imageUrls = [
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-1.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-2.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-3.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-4.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-5.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-6.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-7.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-8.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-9.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-10.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-11.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-12.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-13.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-14.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-15.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-16.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-17.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-18.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-19.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-20.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-21.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-22.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-23.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-24.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-25.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-26.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-27.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-28.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-29.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-30.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-31.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-32.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-33.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-34.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-35.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-36.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-37.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-38.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-39.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-40.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-41.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-42.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-43.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-44.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-45.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-46.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-47.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-48.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-49.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-50.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-51.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-52.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-53.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-54.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-55.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-56.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-57.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-58.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-59.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-60.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-61.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-62.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-63.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-64.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-65.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-66.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-67.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-68.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-69.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-70.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-71.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-72.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-73.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-74.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-75.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-76.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-77.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-78.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-79.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-80.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-81.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-82.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-83.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-84.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-85.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-86.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-87.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-88.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-89.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-90.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-91.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-92.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-93.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-94.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-95.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-96.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-97.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-98.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-99.png",
  "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/avatar/peep-100.png"
];


export default imageUrls;