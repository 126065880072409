import React, { useState } from "react";
import UserNudge from "./UserNudge/UserNudge";
import "./UserMessageAndNudge.css";
import { TalentPoolMessage } from "./TalentPoolMessages/TalentPoolMessage";
import { MentoringMessages } from "./MentoringMessages/MentoringMessages";

export const UserMessageAndNudge = () => {
  const [selectMessageTab, setSelectMessageTab] = useState("nudges");
  const messageTabOptions = [
    {
      name: "Nudges",
      key: "nudges",
    },
    {
      name: "Talent Pool Messages",
      key: "talentpoolmessages",
    },
    {
      name: "Mentoring Message",
      key: "mentoringmessages",
    },
  ];

  const handleMessageTab = (key) => {
    setSelectMessageTab(key);
    console.log("activity tab clicked", key);
  };
  return (
    <div className="userMessageAndNudgePage">
      <div className="messageTags">
        {messageTabOptions?.map((tab, index) => {
          return (
            <div
              className={`flexBetweenRadius10 ${
                tab?.key === selectMessageTab ? "" : "deactiveTab"
              }`}
              key={index}
              onClick={() => handleMessageTab(tab?.key)}
            >
              {tab?.name}
            </div>
          );
        })}
      </div>

      <div className="contentArea">
        {selectMessageTab === "nudges" ? <UserNudge /> : 
        selectMessageTab === "talentpoolmessages" ? <TalentPoolMessage  /> :
        selectMessageTab === "mentoringmessages" ? <MentoringMessages /> : null
      }
      </div>
    </div>
  );
};
