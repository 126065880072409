import React from "react";
import "./DropdownList.css";

export const DropdownList = ({ options, formik, formikFieldName, onClose }) => {
  const changeTheFormikValue = (value) => {
    formik.setFieldValue(formikFieldName, value);
    onClose();
  };
  return (
    <div className="dropdownListContainer">
      {options?.map((element) => {
        return (
          <div onClick={() => changeTheFormikValue(element)}>
            {element}
          </div>
        );
      })}
    </div>
  );
};
