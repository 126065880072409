import React, { useEffect, useState } from "react";

import "./Mentors.css";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdDelete,
} from "react-icons/md";

import { IoFunnelOutline } from "react-icons/io5";
import UserProfile from "../../assets/profile.png";
import TwoButtonWrapper from "../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import axios from "../../axios";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LinkedInUrl from "../../assets/linkedInLogo.png";

export const Mentors = () => {
  const [mentorList, setMentorList] = useState([]);
  const history = useHistory();

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/common/all-mentors", {
        headers: {
          authorization: `Bearer ${Marketer.token}`,
        },
      });
      setMentorList(data.data);
    })();
  }, []);

  const redirectToSessionBooking = (username) => {
    console.log("username is ", username);
    history.push(`/userprofile/${username}/mentoring`);
  };

  const redirectToProfile = (username) => {
    history.push(`/userprofile/${username}`);
  };

  const redirectToLinkedin = (url) => {
    window.open(url, "_blank");
  };

  const redirectToParticularSession = (username, sessionId) => {
    history.push(`/userprofile/${username}/mentoring/${sessionId}`);
  };
  return (
    <div className="MentorsPage">
      <div className="filterSection">
        <div className="filterTab">
          <IoFunnelOutline />
          <p>Filtered By</p>

          <div className="filterTabDropdown">
            <p>Pool Category</p>
            <MdExpandMore />
          </div>

          <div className="filterTabDropdown">
            <p>Company</p>
            <MdExpandMore />
          </div>
        </div>
      </div>

      <div className="mentorsPageContainer">
        {mentorList?.map((element) => {
          return (
            <div className="mentorCard">
              <div className="gridChild mentorUserInfo">
                <img
                  src={element?.mentor?.profilePhoto}
                  alt=""
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                  }}
                />
                <div className="nameAndRole">
                  <p className="mentorName">{element?.mentor?.name}</p>
                  <p className="mentorRole">
                    <p>{element?.mentor?.experience[0]?.role}</p>
                    <p>
                      at{" "}
                      <strong>
                        {element?.mentor?.experience[0]?.companyName}
                      </strong>{" "}
                    </p>
                  </p>
                </div>

                <div className="companiesAssociated">
                  <p className="companyName">Google</p>
                  <p className="companyName">Meta</p>

                  <p className="companyName">Facebook</p>

                  <p className="companyName">Microsoft</p>
                  <p className="companyName">Tasla</p>
                  <p className="companyName">Tata Vehicle Motors</p>
                </div>
              </div>
              <div className="gridChild mentorAboutInfo">
                <p className="aboutPara">
                {element?.mentor?.about}
                </p>

                <button
                  className="linkedInButton"
                  onClick={() =>
                    redirectToLinkedin(element?.mentor?.socialLinks?.linkedIn)
                  }
                >
                  <img
                    src={LinkedInUrl}
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "3px",
                    }}
                  />
                  <p>LinkedIn</p>
                </button>
              </div>
              <div className="gridChild mentorSessionInfo">
                <p className="sessionHeading">Sessions</p>
                <div className="sessionList">
                  {element?.sessions?.map((session) => {
                    return (
                      <TwoButtonWrapper
                        firstComponent={session?.session_name}
                        secondComponent={
                          <span class="material-symbols-outlined">
                            call_made
                          </span>
                        }
                        secondComponentFunction={() =>
                          redirectToParticularSession(
                            element?.mentor?.username,
                            session?._id
                          )
                        }
                        backgroundColor={"white"}
                        color={"black"}
                        height={"40px"}
                        hoverActivate={true}
                      />
                    );
                  })}
                </div>

                <div className="sessionButtons">
                  <TwoButtonWrapper
                    firstComponent={"View Profile"}
                    componentFunction={() =>
                      redirectToProfile(element?.mentor?.username)
                    }
                    backgroundColor={"white"}
                    color={"black"}
                    height={"35px"}
                    borderRadius={"10px"}
                    fontSize={"12px"}
                    padding={"0 10px"}
                  />

                  <TwoButtonWrapper
                    firstComponent={"Book a session"}
                    componentFunction={() =>
                      redirectToSessionBooking(element?.mentor?.username)
                    }
                    height={"35px"}
                    borderRadius={"10px"}
                    fontSize={"12px"}
                    padding={"0 10px"}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
