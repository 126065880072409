import React, { useEffect, useState } from "react";
import "./Pools.css";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdDelete,
} from "react-icons/md";

import { IoFunnelOutline } from "react-icons/io5";
import UserProfile from "../../assets/profile.png";
import TwoButtonWrapper from "../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import axios from "../../axios";

export const Pools = () => {
  const [categoryList, setCategoryList] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/common/all-pools", {
        headers: {
          authorization: `Bearer ${Marketer.token}`,
        },
      });
      setCategoryList(data.data);
    })();
  }, []);
  return (
    <div className="PoolsPage">
      <div className="filterSection">
        <div className="filterTab">
          <IoFunnelOutline />
          <p>Filtered By</p>

          <div className="filterTabDropdown">
            <p>Pool Category</p>
            <MdExpandMore />
          </div>

          <div className="filterTabDropdown">
            <p>Company</p>
            <MdExpandMore />
          </div>

          <div className="filterTabDropdown">
            <p>Endorser</p>
            <MdExpandMore />
          </div>
        </div>
      </div>

      <div className="poolsPageContainer">
        {categoryList?.map((category) => {
          return (
            <div className="poolCard">
              <div className="poolNameInfo">
                <p className="poolName lighterFont">
                  {category?.name} | {category?.user_count}
                </p>
                <p className="poolPrice">{category?.price_per_user}/profile</p>
              </div>
              <p className="lighterFont">By</p>
              <TwoButtonWrapper
                firstComponent={
                  <img
                    src={category?.user_id?.profilePhoto}
                    style={{
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                    }}
                  />
                }
                secondComponent={category?.user_id?.name}
                backgroundColor={"white"}
                color={"black"}
                fontSize={"16px"}
              />

              {category?.user_id?.experience.length > 0 && (
                <>
                  <p className="lighterFont">From</p>
                  <TwoButtonWrapper
                    firstComponent={
                      <img
                        src={category?.user_id?.experience[0].companyLogo}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                      />
                    }
                    secondComponent={
                      category?.user_id?.experience[0].companyName
                    }
                    backgroundColor={"white"}
                    color={"black"}
                    fontSize={"16px"}
                  />
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
