import React, { useEffect, useState } from "react";
import "./WaveHandConfirmation.css";
import TwoButtonWrapper from "../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { DropdownList } from "../../atoms/DropdownList/DropdownList";
import { useFormik } from "formik";
import axios from "../../axios";
import { toast } from "react-toastify";
import toastStyle from "../../assets/toastConfig";
const formLabelList = [
  {
    label: "Role",
    name: "role",
    placeholder: "Enter the role for which you are hiring",
    type: "text",
    inputType: "input",
  },
  {
    label: "Salary Range",
    name: "salaryRange",
    placeholder: "Select salary range",
    type: "string",
    inputType: "input",
    subtext: "INR | USD",
    options: [
      "2 LPA - 5 LPA",
      "5 LPA - 10 LPA",
      "10 LPA - 15 LPA",
      "15 LPA - 20 LPA",
      "20 LPA - 30 LPA",
      "30 LPA - 40 LPA",
      "40 LPA - 50 LPA",
      "50 LPA and above",
    ],
  },
  {
    label: "Experience",
    name: "experience",
    placeholder: "What is the required experience for this role?",
    type: "string",
    inputType: "input",
    subtext: "(in years)",
    options: ["0-1", "1-3", "3-5", "5-7", "7-10", "10+"],
  },
  {
    label: "Job Description",
    name: "jobDescription",
    placeholder: "Your description in a few lines",
    type: "text",
    inputType: "textarea",
  },
  {
    label: "Joining Date",
    name: "joiningDate",
    placeholder: "When do you want the candidate to join?",
    type: "string",
    inputType: "input",
    options: [
      "Immediate Joiner",
      "Within 1 Week",
      "Within 2 Weeks",
      "Within 1 Month",
      "Within 2 Months",
      "Within 3 Months",
      "After 3 Months",
      "Flexible Joining Date",
    ],
  },
  {
    label: "Company",
    name: "company",
    placeholder: "What company are you representing?",
    type: "text",
    inputType: "input",
  },
];
export const WaveHandConfirmation = ({ onClose, onConfirm, params }) => {
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const formik = useFormik({
    initialValues: {
      role: "",
      salaryRange: "",
      experience: "",
      jobDescription: "",
      joiningDate: "",
      company: "",
    },
    onSubmit: async (values) => {
      const { data } = await axios.post(
        "/nudges/create-new-template",
        { ...values, marketer_id: Marketer?.user?._id },
        {
          headers: {
            Authorization: `Bearer ${Marketer?.token}`,
          },
        }
      );
      if (data?.success) {
        toast.success(data?.message, toastStyle);
      } else {
        toast.error(data?.message, toastStyle);
      }
    },
  });

  const [dropdown, setDropdown] = useState({});
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [previousSearchValue, setPreviousSearchValue] = useState("");

  const handleDropDown = (name) => {
    setDropdown({ [name]: !dropdown[name] });
  };

  useEffect(() => {
    if (formik.values.role !== previousSearchValue) {
      (async () => {
        if (formik.values.role.length === 1 || formik.values.role.length > 1) {
          const { data } = await axios.post(
            "/nudges/get-template-by-role",
            { role: formik.values.role },
            {
              headers: {
                Authorization: `Bearer ${Marketer?.token}`,
              },
            }
          );

          if (data?.success) {
            setFilteredTemplates(data?.data);
          }
        } else if (formik.values.role.length === 0) {
          setFilteredTemplates([]);
        }
      })();
    }
  }, [formik.values.role]);

  const handleTemplateSelect = (template) => {
    setPreviousSearchValue(template?.role);
    formik.setValues({
      role: template.role || "",
      salaryRange: template.salaryRange || "",
      experience: template.experience || "",
      jobDescription: template.jobDescription || "",
      joiningDate: template.joiningDate || "",
      company: template.company || "",
    });
    setFilteredTemplates([]);
  };

  console.log("the filtered templates are", filteredTemplates);

  return (
    <div className="confirmation-box">
      <div className="confirmation-modal-content">
        <div className="confirmation-form">
          {formLabelList?.map((item, index) => {
            return (
              <div className="inputAndLabelContainer">
                <div className="labelContainer">
                  <label htmlFor="role">{item?.label}</label>
                  {item?.subtext && (
                    <p className="labelSubtext">{item?.subtext}</p>
                  )}
                </div>
                <div
                  className={`inputContainer ${
                    item?.inputType === "textarea" ? "textAreaInput" : ""
                  }`}
                >
                  {item?.inputType === "textarea" ? (
                    <textarea
                      name={item?.name}
                      value={formik.values[item?.name]}
                      onChange={formik.handleChange}
                    ></textarea>
                  ) : (
                    <>
                      <input
                        type={item?.type}
                        name={item?.name}
                        placeholder={item?.placeholder}
                        value={formik.values[item?.name]}
                        onChange={formik.handleChange}
                      />

                      {item?.options && (
                        <div onClick={() => handleDropDown(item?.name)}>
                          {!dropdown[item?.name] ? (
                            <MdExpandMore></MdExpandMore>
                          ) : (
                            <MdExpandLess></MdExpandLess>
                          )}
                        </div>
                      )}

                      {item?.options && dropdown[item?.name] && (
                        <DropdownList
                          options={item?.options}
                          formik={formik}
                          formikFieldName={item?.name}
                          onClose={() => handleDropDown(item?.name)}
                        />
                      )}

                      {item?.name === "role" && filteredTemplates?.length > 0 && (
                        <div className="dropdownTemplateListContainer">
                          {filteredTemplates?.map((template) => {
                            return (
                              <div
                                onClick={() => handleTemplateSelect(template)}
                                className="singleTemplateInfo"
                              >
                                <p>{template?.role}</p>
                                <p>
                                  {template?.experience} {" years"}
                                </p>
                                <p>{template?.salaryRange}</p>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            );
          })}

          <div className="saveTemplateContainer">
            <div className="saveTemplateHeadingLine">
              <span className="material-symbols-outlined">info</span>
              <p>Save this “Availability Check” template</p>
            </div>
            <div className="saveTemplateDescription">
              To avoid repeated filling up this form, you can save the details.
              You can convert this into a full-fledged job post in the future
            </div>
            <TwoButtonWrapper
              firstComponent={
                <span className="material-symbols-outlined">bookmark</span>
              }
              secondComponent={"Save"}
              backgroundColor={"white"}
              color={"black"}
              borderColor={"#E0E0E0"}
              padding={"5px 10px"}
              height={"45px"}
              borderRadius={"10px"}
              componentFunction={() => formik.handleSubmit()}
            />
          </div>
        </div>
        <div className="modalButtons">
          <TwoButtonWrapper
            firstComponent={"Cancel"}
            borderColor={"#E0E0E0"}
            backgroundColor={"white"}
            color={"black"}
            borderRadius={"10px"}
            height={"45px"}
            padding={"5px 20px"}
            componentFunction={() => onClose()}
          ></TwoButtonWrapper>

          <TwoButtonWrapper
            firstComponent={"Save"}
            borderRadius={"10px"}
            height={"45px"}
            padding={"5px 20px"}
            componentFunction={() =>
              onConfirm({ ...params, message: formik.values })
            }
          />
        </div>
      </div>
    </div>
  );
};
