import React, { useState } from "react";
import "./AddNewCandidate.css";
import TwoButtonWrapper from "../../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { toast } from "react-toastify";
import axios from "../../../../../axios";
import { Dropdown } from "bootstrap";
import magnify from "../demoImages/magnify.svg";
import { DropdownContentArea } from "../../../../../atoms/DropdownContentArea";
import { UnispadeBlack } from "../../../../HomePageComponents/images";
import ToggleSwitch from "../../../../../atoms/ToggleSwitch/ToggleSwitch";
import { RxCross2 } from "react-icons/rx";
import toastStyle from "../../../../../assets/toastConfig";

export const AddNewCandidate = ({
  setcopyUserData,
  setAddedUser,
  selectedCategory,
}) => {
  const [userPrice, setUserPrice] = useState(selectedCategory?.price_per_user);
  const [isTopCandidate, setIsTopCandidate] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [skillSearchValue, setSkillSearchValue] = useState("");
  const [tagSeachValue, setTagSearchValue] = useState("");
  const [suggestedSkills, setSuggestedSkills] = useState([]);
  const [suggestedTags, setSuggestedTags] = useState([]);

  const [stateForNormal, setStateForNormal] = useState(false);

  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [rejectionComment, setrejectionComment] = useState("");

  const [activateInviteButton, setActivateInviteButton] = useState(false);
  const [users, setUsers] = useState([]); // ye bhejna hai
  const [filteredUsers, setFilteredUsers] = useState([]); // ye bhejna hai

  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  async function handleSearch(e) {
    setSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const { data } = await axios.post(
        "/marketers/getAllMarketers",
        { userNameStartWith: e.target.value.toLowerCase() },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );
      setUsers(data);
      setFilteredUsers(data);
    } else {
      let user = users.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setFilteredUsers(user);
    }
  }

  async function handleSkillsSearch(e) {
    setSkillSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_skills/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      console.log("all skills", data?.data);
      setSuggestedSkills(data?.data);
    } else {
      let skills = suggestedSkills.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedSkills(skills);
    }
  }

  async function handleTagsSearch(e) {
    setTagSearchValue(e.target.value);
    if (e.target.value.length === 1) {
      const param = e.target.value.toLowerCase();
      const { data } = await axios.get(`talentPool/find_tags/${param}`, {
        headers: { authorization: "Bearer " + Marketer.token },
      });
      console.log("all tags", data?.data);
      setSuggestedTags(data?.data);
    } else {
      let tags = suggestedTags.filter((key) =>
        key.name.toLowerCase().startsWith(e.target.value.toLowerCase())
      );
      setSuggestedTags(tags);
    }
  }

  async function selectSuggestedTag(tag) {
    setSelectedTags((prevData) => [...prevData, tag]);
    setTagSearchValue("");
  }

  async function selectSuggestedSkill(skill) {
    setSelectedSkills((prevData) => [...prevData, skill]);
    setSkillSearchValue("");
  }
  // async function removeExistingTag(tag) {
  //   const newSelectedTags = selectedTags?.filter((e) => {
  //     e._id !== tag._id;
  //   });
  //   setSelectedTags(newSelectedTags);
  //   // setStateForNormal(!stateForNormal);
  // }

  // async function removeExistingSkill(skill) {
  //   const newSelectedSkills = selectedSkills?.filter((e) => {
  //     e._id !== skill._id;
  //   });
  //   setSelectedSkills(newSelectedSkills);
  //   // setStateForNormal(!stateForNormal);
  // }

  async function removeExistingTag(tag) {
    const newSelectedTags = selectedTags?.filter((e) => e._id !== tag._id);
    setSelectedTags(newSelectedTags);
    // setStateForNormal(!stateForNormal);
  }

async function removeExistingSkill(skill) {
    const newSelectedSkills = selectedSkills?.filter((e) => e._id !== skill._id);
    setSelectedSkills(newSelectedSkills);
    // setStateForNormal(!stateForNormal);
  }

  async function handleInviteUser() {
    try {
      const skillIds = selectedSkills.map((skill) => skill._id);
      const tagIds = selectedTags.map((tag) => tag._id);
      const { data } = await axios.post(
        "/talentPool/invite_talent_pool_users",
        {
          category_id: selectedCategory._id,
          userEmail: searchValue,
          rejectionComment: rejectionComment,
          skills: skillIds,
          tags: tagIds,
          user_price: userPrice,
          is_top_candidate: isTopCandidate,
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      if (data.success === false) {
        toast.error(data.message, toastStyle);
      } else if (data.success) {
        setcopyUserData((prev) => {
          return [...prev, data?.data || []];
        });
        toast.success(data.message, toastStyle);
        handleCancelButton();
      }
      console.log(data);
      setSearchValue("");
    } catch (error) {
      console.log(error);
    }
  }

  async function handleAddUser(email) {
    try {
      const skillIds = selectedSkills.map((skill) => skill._id);
      const tagIds = selectedTags.map((tag) => tag._id);

      const { data } = await axios.post(
        "/talentPool/create_talent_pool_users",
        {
          category_id: selectedCategory._id,
          userEmail: email,
          rejectionComment: rejectionComment,
          skills: skillIds,
          tags: tagIds,
          user_price: userPrice,
          is_top_candidate: isTopCandidate,
        },
        { headers: { authorization: "Bearer " + Marketer.token } }
      );

      if( data.success ) {
        toast.success(data.message, toastStyle)
        setcopyUserData((prev) => {
          return [...prev, data?.data?.talentPoolUser];
        });
      }
      handleCancelButton();
    } catch (error) {
      console.log(error);
    }
  }

  const handleCancelButton = () => {
    setActivateInviteButton(false);
    setSearchValue("");
    setSkillSearchValue("");
    setTagSearchValue("");
    setSuggestedSkills([]);
    setSuggestedTags([]);
    setSelectedSkills([]);
    setSelectedTags([]);
  };
  return (
    <div className="addNewCandidateContainer">
      <div className="searchBarElement">
        {!activateInviteButton ? (
          <>
            <div
              style={{
                width: "80%",
              }}
            ></div>
            <TwoButtonWrapper
              firstComponent={"+"}
              secondComponent={"Add candidates"}
              height={"45px"}
              borderRadius={"10px"}
              componentFunction={() => setActivateInviteButton(true)}
            ></TwoButtonWrapper>
          </>
        ) : (
          <>
            <input
              type="text"
              name=""
              id=""
              value={searchValue}
              onChange={handleSearch}
              placeholder="Add people to your talent pool. Type the Name to search or invite via Email ID."
            />
            <TwoButtonWrapper
              firstComponent={"Invite"}
              height={"50px"}
              borderRadius={"10px"}
              componentFunction={() => handleInviteUser()}
            ></TwoButtonWrapper>
          </>
        )}

        {searchValue.length > 0 && filteredUsers.length > 0 && (
          <div className="searchBarResultContainer">
            {filteredUsers?.map((element) => {
              return (
                <div className="resultItem">
                  <div className="user-basic-data-header">
                    <div className="profile-img"></div>
                    <p>
                      {element?.name} | <span>{element?.email}</span>
                    </p>
                  </div>

                  <TwoButtonWrapper
                    firstComponent={"Add"}
                    componentFunction={() => handleAddUser(element?.email)}
                    height={"45px"}
                    borderRadius={"10px"}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>

      {activateInviteButton && (
        <DropdownContentArea>
          <div className="inviteDetailContainer">
            <div className="detailColumns">
              <div className="verticalLabelInput">
                <label htmlFor="userName">Name</label>
                <input
                  type="text"
                  className="detailInput fullName"
                  placeholder="First Name + Last Name"
                />
              </div>
            </div>
            <div className="detailColumns">
              <div className="horizontalLabelInput">
                <label htmlFor="userName">Custom Profile Pricing</label>
                <input
                  type="number"
                  className="detailInput userPrice"
                  value={userPrice}
                  onChange={(e) => setUserPrice(e.target.value)}
                />
              </div>

              <div className="horizontalLabelInput">
                <label htmlFor="userName">
                  Make them the top candidate in your pool
                </label>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ToggleSwitch
                    isTopCandidate={isTopCandidate}
                    setIsTopCandidate={setIsTopCandidate}
                  />
                </div>
              </div>
            </div>

            <div className="detailColumns">
              <div className="verticalLabelInput">
                <label htmlFor="addSkills">Add Skills</label>
                <div className="skillAndCustomTags">
                  <div className="seachBarWithSuggestion">
                    <div className="searchBar">
                      <img src={magnify} alt="" />
                      <input
                        type="text"
                        placeholder="Add skills"
                        value={skillSearchValue}
                        onChange={(e) => handleSkillsSearch(e)}
                      />
                    </div>

                    {suggestedSkills.length > 0 && skillSearchValue.length > 0 && (
                      <div className="suggestionBox">
                        {suggestedSkills?.map((element) => {
                          return (
                            <div
                              className="skillOrTagItem"
                              style={{
                                backgroundColor: element?.backgroundColor,
                                border: `1px solid ${element?.borderColor}`,
                              }}
                              onClick={() => selectSuggestedSkill(element)}
                            >
                              {element.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="skillAndTagContainer">
                    {selectedSkills?.map((element) => {
                      return (
                        <div
                          className="skillOrTagItem"
                          style={{
                            backgroundColor: element?.backgroundColor,
                            border: `1px solid ${element?.borderColor}`,
                          }}
                        >
                          {element.name}
                          <RxCross2
                            className="crossIcon"
                            onClick={() => removeExistingSkill(element)}
                          ></RxCross2>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="verticalLabelInput">
                <label htmlFor="customTags">Custom Tags</label>
                <div className="skillAndCustomTags">
                  <div className="seachBarWithSuggestion">
                    <div className="searchBar">
                      <img src={magnify} alt="" />
                      <input
                        type="text"
                        placeholder="Add tags"
                        value={tagSeachValue}
                        onChange={(e) => handleTagsSearch(e)}
                      />
                    </div>
                    {suggestedTags.length > 0 && tagSeachValue.length > 0 && (
                      <div className="suggestionBox">
                        {suggestedTags?.map((element) => {
                          return (
                            <div
                              className="skillOrTagItem"
                              style={{
                                backgroundColor: element?.backgroundColor,
                                border: `1px solid ${element?.borderColor}`,
                              }}
                              onClick={() => selectSuggestedTag(element)}
                            >
                              {element.name}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <div className="skillAndTagContainer">
                    {selectedTags?.map((element) => {
                      return (
                        <div
                          className="skillOrTagItem"
                          style={{
                            backgroundColor: element?.backgroundColor,
                            border: `1px solid ${element?.borderColor}`,
                          }}
                        >
                          {element.name}
                          <RxCross2
                            className="crossIcon"
                            onClick={() => removeExistingTag(element)}
                          ></RxCross2>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="verticalLabelInput">
              <label htmlFor="candidateComments">Comments</label>

              <textarea
                name="candidateComments"
                id="candidateComments"
                className="detailInput userComments"
                rows="4"
                value={rejectionComment}
                onChange={(e) => setrejectionComment(e.target.value)}
              ></textarea>
            </div>

            <div className="inviteDetailButtons">
              <TwoButtonWrapper
                firstComponent={"Cancel"}
                height={"45px"}
                borderRadius={"10px"}
                backgroundColor={"white"}
                color={"black"}
                componentFunction={() => handleCancelButton()}
              ></TwoButtonWrapper>

              <TwoButtonWrapper
                firstComponent={"Invite"}
                height={"45px"}
                borderRadius={"10px"}
                backgroundColor={"black"}
                color={"white"}
                componentFunction={() => handleInviteUser()}
              ></TwoButtonWrapper>
            </div>
          </div>
        </DropdownContentArea>
      )}
    </div>
  );
};
