const toastStyle = {
  position: "top-center",
  autoClose: 2000,
  pauseOnHover: false,
  draggable: true,
  theme: "light",
  width: "10rem" ,
  border : "1px solid black",
};

export default toastStyle;
