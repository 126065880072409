import { useEffect, useState } from 'react';
import './ToggleSwitch.css';
const  ToggleSwitch = ({isTopCandidate,user_id,  setIsTopCandidate}) => {

  const handleChange = (event) => {
    if(user_id) {
      setIsTopCandidate({[user_id] : event.target.checked});
    }
    else {
      setIsTopCandidate(event.target.checked);
    }
  };

  return (
    <label className="switch">
      <input type="checkbox" checked={isTopCandidate} onChange={handleChange} />
      <span className="slider round"></span>
    </label>
  );
}

export default ToggleSwitch;