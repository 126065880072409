import React, { useState, useRef, useEffect } from "react";
import "./ChatBoxComponent.css";
import { MdAttachFile } from "react-icons/md";
import { IoSendOutline } from "react-icons/io5";
import UserDefault from "../../components/MarketerDashbCompo/Talent/TalentPool/demoImages/userDefault.jpg";
import axios from "../../axios.js";

export const ChatBoxComponent = ({
  chatMessages,
  setChatMessages,
  chatBoxId,
  type,idToProfile,
  ...props
}) => {
  const [message, setMessage] = useState("");
  const inputRef = useRef(null);
  const chatAreaRef = useRef(null);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    if (chatAreaRef.current) {
      chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
    }
  }, [message]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const sendMessage = async () => {
    if (type === "talentPool") {
      sendTalentPoolMessage();
    } else if (type === "admission") {
      sendRequestAdmissionMessage();
    }
    else if (type === "mentoring") {
      sendMentoringMessage();
    }
  };

  const sendMentoringMessage = async () => {
    const { data } = await axios.post(
      `/mentoring/chat/send_message/${chatBoxId}`,
      {
        sender_id: Marketer?.user?._id,
        message: message,
      },
      {
        headers: { authorization: "Bearer " + Marketer.token },
      }
    );
    setChatMessages((prevData) => [...prevData, data?.data]);
    setMessage("");
  };

  const sendTalentPoolMessage = async () => {
    const { data } = await axios.post(
      `/talentPool/chat/send_message/${chatBoxId}`,
      {
        sender_id: Marketer?.user?._id,
        message: message,
      },
      {
        headers: { authorization: "Bearer " + Marketer.token },
      }
    );
    setChatMessages((prevData) => [...prevData, data?.data]);
    setMessage("");
  };

  const sendRequestAdmissionMessage = async () => {
    const { data } = await axios.post(
      `/talentPool/chat/send-admission-message/${chatBoxId}`,
      {
        sender_id: Marketer?.user?._id,
        message: message,
      },
      {
        headers: { authorization: "Bearer " + Marketer.token },
      }
    );
    setChatMessages((prevData) => [...prevData, data?.data]);
    setMessage("");
  };

  const mainClass = {
    height: props?.height || "max-content",
  };
  
  
  const secondMainClass = {
    padding: props?.padding || "30px 50px",
    textAlign: "left",
    marginBottom: "10px",
  };


  return (
    <div className="chatBoxComponent" style={mainClass}>
      <div className="entireArea" style={secondMainClass}>
        <div className="chatArea" ref={chatAreaRef}>
          {chatMessages?.map((message, index) => {
            return (
              <div
                className={`messageBox ${
                  message?.sender_id === Marketer?.user?._id ? "sent" : "received"
                }`}
                key={index}
              >
                <img
                  src={idToProfile[message?.sender_id] || UserDefault}
                  alt=""
                  style={{
                    height: "50px",
                    width: "50px",
                    borderRadius: "50%",
                  }}
                />
                <div key={index} className={`message`}>
                  {message?.message}
                </div>
              </div>
            );
          })}
        </div>
        <div className="sendTextArea">
          <div className="searchBarElement">
            <input
              type="text"
              name=""
              id=""
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendMessage();
                }
              }}
            />

            <div className="attachFile">
              <MdAttachFile
                style={{
                  height: "24px",
                  width: "24px",
                  color: "#1C1B1F",
                }}
              />
            </div>
          </div>

          <div className="sendButton" onClick={() => sendMessage()}>
            <IoSendOutline
              style={{
                height: "16px",
                width: "20px",
                color: "#FFFFFF",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
