import React, { useEffect, useState } from "react";
import UserInfoSlate from "../../../../atoms/UserInfoSlate/UserInfoSlate";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";
import { IoFunnelOutline } from "react-icons/io5";
import { HiOutlineLockOpen } from "react-icons/hi2";
import "./ExternalActivity.css";
import linkedLogoUrl from "../../img/linkedInLogo.png";
import ProfilePic from "../../img/profile.png";
import axios from "../../../../axios";
import { useHistory } from "react-router-dom";
import Loader from "../../../loader/Loader";

const variousStatus = [
  {
    _id: 1,
    name: "SENT",
  },
  {
    _id: 2,
    name: "AVAILABLE",
  },
  {
    _id: 3,
    name: "NOT-AVAILABLE",
  },
  {
    _id: 4,
    name: "UNLOCKED",
  },
];

export const ExternalActivity = () => {
  const history = useHistory();
  const [requests, setRequests] = useState([]);
  const [tempRequestData, setTempRequestData] = useState([]);
  const [unlockedUsers, setUnlockedUsers] = useState([]);
  const [providedFilterOptions, setProvidedFilterOptions] = useState();
  const [filterList, setFilterList] = useState([]);
  const [profileDropdown, setFilterDropdown] = useState({});
  const [entireData, setEntireData] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/userActivity/get-external-user-activity/${Marketer.user?._id}`,
        {
          headers: {
            Authorization: `Bearer ${Marketer.token}`,
          },
        }
      );
      setRequests(data?.data?.list);
      setTempRequestData(data?.data?.list);
      setProvidedFilterOptions(data?.data?.filters);

      setRequests([...data?.data?.list, ...data?.data?.unlockedUsers]);
      setTempRequestData([...data?.data?.list, ...data?.data?.unlockedUsers]);

      // setUnlockedUsers(data?.data?.unlockedUsers);
      setLoading(false);
      console.log("The use effect is called");
    })();
  }, []);

  console.log("The entire data is ", requests);
  useEffect(() => {
    if (filterList.length === 0) {
      setTempRequestData(requests);
    } else {
      const filteredData = applyFilters(requests, filterList);
      setTempRequestData(filteredData);
    }
  }, [filterList]);

  const handleFilterDropdown = (filter) => {
    setFilterDropdown({
      [filter]: !profileDropdown[filter],
    });
  };
  const addFilter = (filterData, type) => {
    setFilterList((prevFilters) => [...prevFilters, { filterData, type }]);
    setFilterDropdown({});
  };
  const removeFilter = (filter_id) => {
    setFilterList((prevFilters) =>
      prevFilters.filter((item) => item.filterData._id !== filter_id)
    );
  };

  const applyFilters = (data, filters) => {
    let filteredData = data;

    filters.forEach(({ filterData: filterValue, type }) => {
      if (type === "PoolCategory") {
        filteredData = filteredData.filter(
          (element) => element?.category_id?._id === filterValue?._id
        );
      } else if (type === "PoolOwner") {
        filteredData = filteredData.filter(
          (element) => element?.pool_owner?._id === filterValue?._id
        );
      } else if (type === "Status") {
        filteredData = filteredData.filter(
          (element) => element?.current_status === filterValue?.name
        );
      }
    });

    return filteredData;
  };

  const handleCreatePayment = async (data) => {
    const membersToBeUnlocked = [data?.receiver_id?._id];
    const priceToPay = data?.pool_member?.user_price;

    const gstAmount = (priceToPay * 18) / 100;
    const categoryId = data?.category_id?._id;

    const payload = {
      talentPoolCategoriesId: categoryId,
      membersToBeUnlocked: membersToBeUnlocked,
      amount: priceToPay,
      gst: gstAmount,
      pool_owner: data?.pool_owner?._id,
      totalAmount: priceToPay + gstAmount,
    };

    console.log("the razor pay payload is ", payload);

    try {
      const response = await axios
        .post("/create-order", payload, {
          headers: { authorization: "Bearer " + Marketer?.token },
        })
        .then((res) => {
          if (res?.status === 200) {
            const options = {
              key: process.env.REACT_APP_RAZORPAY_KEY_ID,
              amount: res?.data?.totalAmount,
              currency: "INR",
              name: "Unispade",
              description: "RazorPay",
              order_id: res?.data?.razorPayOrderId,
              async handler(response) {
                const verifydata = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                };
                try {
                  setLoading(true);
                  const verifyResponse = await axios.post(
                    "/verify-payment",
                    verifydata,
                    {
                      headers: { authorization: "Bearer " + Marketer?.token },
                    }
                  );

                  // console.log(verifyResponse, "verifyResponse");

                  if (verifyResponse?.status === 200) {
                    await axios.post(
                      "/update-talent-payment",
                      {
                        pool_owner: data?.pool_owner?._id,
                        buyer_id: Marketer?.user?._id,
                        category_id: categoryId,
                        membersToBeUnlocked,
                      },
                      {
                        headers: { authorization: "Bearer " + Marketer?.token },
                      }
                    );

                    history.push(`/activitypaymentsuccess`);
                  } else {
                    setLoading(false);
                    history.push("/payment-failed");
                  }
                } catch (verifyError) {
                  console.error("Error while verifying payment:", verifyError);
                }
              },
              notes: {
                address: "Razorpay Corporate Office",
              },
              theme: {
                color: "#121212",
              },
            };
            const razor = new window.Razorpay(options);
            razor.open();
          }
        });
    } catch (error) {
      console.error("Error while creating payment:", error);
    }
  };

  const handlePoolOwnerRedirect = (username) => {
    history.push(`/userprofile/${username}/talentPool`);
  };
  const handleClick = (link) => {
    window.open(link, "_blank");
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="ExternalActivityTab">
          <div className="filterSection">
            <div className="filterTab">
              <div className="filterIcon">
                <IoFunnelOutline />
                <p>Filtered By</p>
              </div>

              <div className="filteringOption">
                <div className="filterTabDropdown">
                  <p>Pool Category</p>
                  <div onClick={() => handleFilterDropdown("PoolCategory")}>
                    {profileDropdown["PoolCategory"] ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    )}
                  </div>
                  {profileDropdown["PoolCategory"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["PoolCategory"]?.map(
                        (category) => {
                          return (
                            <div
                              onClick={() =>
                                addFilter(category, "PoolCategory")
                              }
                            >
                              {category?.name}
                            </div>
                          );
                        }
                      )}
                    </div>
                  )}
                </div>

                <div className="filterTabDropdown">
                  <p>Pool Owner</p>
                  <div onClick={() => handleFilterDropdown("PoolOwner")}>
                    {profileDropdown["PoolOwner"] ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    )}
                  </div>
                  {profileDropdown["PoolOwner"] && (
                    <div className="variousOptions">
                      {providedFilterOptions["PoolOwner"]?.map((owner) => {
                        return (
                          <div onClick={() => addFilter(owner, "PoolOwner")}>
                            {owner?.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>

                <div className="filterTabDropdown">
                  <p>Request Status</p>
                  <div onClick={() => handleFilterDropdown("Status")}>
                    {profileDropdown["Status"] ? (
                      <MdExpandLess />
                    ) : (
                      <MdExpandMore />
                    )}
                  </div>
                  {profileDropdown["Status"] && (
                    <div className="variousOptions">
                      {variousStatus?.map((status) => {
                        return (
                          <div onClick={() => addFilter(status, "Status")}>
                            {status?.name}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>

              {filterList?.length > 0 && (
                <>
                  <div></div>
                  <div className="selectedFilters">
                    {filterList?.map((filter) => {
                      return (
                        <div className={`singleFilter ${filter?.type}`}>
                          {filter?.filterData?.name}
                          <RxCross2
                            className="crossIcon"
                            onClick={() =>
                              removeFilter(filter?.filterData?._id)
                            }
                          ></RxCross2>
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="externalActivityArea">
            {tempRequestData?.map((request, index) => {
              return (
                <div className="oneRequestSent">
                  {request?.current_status === "UNLOCKED" ? (
                    <UserInfoSlate
                      user_name={request?.receiver_id?.name}
                      image_url={
                        request?.receiver_id?.profilePhoto || ProfilePic
                      }
                    />
                  ) : (
                    <UserInfoSlate
                      user_name={request?.receiver_id?.ref_id}
                      image_url={request?.receiver_id?.avatar || ProfilePic}
                    />
                  )}

                  <div className="aboutPool">
                    <p>{request?.category_id?.name || "No category written"}</p>
                    <UserInfoSlate
                      user_name={request?.pool_owner?.name}
                      height="45px"
                      imageSize="30px"
                      image_url={
                        request?.pool_owner?.profilePhoto || ProfilePic
                      }
                      componentFunction={() =>
                        handlePoolOwnerRedirect(request?.pool_owner?.username)
                      }
                    />
                  </div>

                  {request?.current_status === "NOT-AVAILABLE" ? (
                    <div className="requestResponse">
                      <TwoButtonWrapper
                        firstComponent={
                          <MdOutlineWavingHand></MdOutlineWavingHand>
                        }
                        backgroundColor={"white"}
                        color={"red"}
                      />
                      <TwoButtonWrapper
                        firstComponent={<RxCross2></RxCross2>}
                        backgroundColor={"white"}
                        color={"black"}
                      />
                    </div>
                  ) : request?.current_status === "AVAILABLE" ? (
                    <div className="requestResponse">
                      <TwoButtonWrapper
                        firstComponent={
                          <MdOutlineWavingHand></MdOutlineWavingHand>
                        }
                        backgroundColor={"white"}
                        color={"green"}
                      />
                      <TwoButtonWrapper
                        firstComponent={<HiOutlineLockOpen></HiOutlineLockOpen>}
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() => handleCreatePayment(request)}
                      />
                    </div>
                  ) : request?.current_status === "SENT" ? (
                    <div className="requestResponse">
                      <TwoButtonWrapper
                        firstComponent={<MdDoneAll></MdDoneAll>}
                        backgroundColor={"white"}
                        color={"black"}
                      />
                    </div>
                  ) : request?.current_status === "UNLOCKED" ? (
                    <div className="requestResponse">
                      <TwoButtonWrapper
                        firstComponent={
                          <span class="material-symbols-outlined">chat</span>
                        }
                        backgroundColor={"white"}
                        color={"black"}
                        // componentFunction={() => handleChatButton(request?._id, request?.chat_inbox)}
                      />
                      <TwoButtonWrapper
                        firstComponent={
                          <span class="material-symbols-outlined">
                            demography
                          </span>
                        }
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() =>
                          handleClick(request?.receiver_id?.resume)
                        }
                      />
                      <TwoButtonWrapper
                        firstComponent={
                          <img src={linkedLogoUrl} className="icon20size" />
                        }
                        backgroundColor={"white"}
                        color={"black"}
                        componentFunction={() =>
                          handleClick(
                            request?.receiver_id?.socialLinks?.linkedIn
                          )
                        }
                      />
                    </div>
                  ) : null}
                </div>
              );
            })}

            {/* {unlockedUsers?.map((request, index) => {
              return (
                <div className="oneRequestSent">
                  <div className="aboutPool">
                    <p>{request?.category_id?.name || "No category written"}</p>
                    <UserInfoSlate
                      user_name={request?.pool_owner?.name}
                      height="45px"
                      imageSize="30px"
                      image_url={
                        request?.pool_owner?.profilePhoto || ProfilePic
                      }
                      componentFunction={() =>
                        handlePoolOwnerRedirect(request?.pool_owner?.username)
                      }
                    />
                  </div>

                  <div className="requestResponse">
                    <TwoButtonWrapper
                      firstComponent={
                        <span class="material-symbols-outlined">chat</span>
                      }
                      backgroundColor={"white"}
                      color={"black"}
                      // componentFunction={() => handleChatButton(request?._id, request?.chat_inbox)}
                    />
                    <TwoButtonWrapper
                      firstComponent={
                        <span class="material-symbols-outlined">
                          demography
                        </span>
                      }
                      backgroundColor={"white"}
                      color={"black"}
                      componentFunction={() =>
                        handleClick(request?.receiver_id?.resume)
                      }
                    />
                    <TwoButtonWrapper
                      firstComponent={
                        <img src={linkedLogoUrl} className="icon20size" />
                      }
                      backgroundColor={"white"}
                      color={"black"}
                      componentFunction={() =>
                        handleClick(request?.receiver_id?.socialLinks?.linkedIn)
                      }
                    />
                  </div>
                </div>
              );
            })} */}
          </div>
        </div>
      )}
    </>
  );
};
