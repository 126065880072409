import React, { useState } from "react";
import BasicInfo from "./BasicInfo/BasicInfo";
import Links from "./Links/Links";
import Experience from "./Experience/Experience";
import Availability from "./AvailabilityResume/AvailabilityResume";
import "./Profile.css";
import { Education } from "./Education/Education";

const Profile = ({
  query,
  setQuery,
  userData,
  setUserData,
  currentProfileData,
  setCurrentProfileData,
  caseStudies,
  onsubmitForm,
  socialLinks,
  setMarketersdata
}) => {
  const [activeBtn, setActiveBtn] = useState("basicInfo");
  const [isDisabled, setIsDisabled] = useState(query ? true : false);
  // const userprofileInit = {
  //     name: userData?.name,
  //     officialMailId: userData?.officialMailId,
  //     username: userData?.username,
  //     email: userData?.email,
  //     password: userData?.password,
  //     confirmPassword: userData?.password,
  //     role: userData?.role,
  //     about: userData?.about,
  //     profilePhoto: userData?.profilePhoto,
  //     workDetails : userData?.workDetails
  // };

  // const [userProfile, setUserProfile] = useState(userprofileInit);

  const btns = [
    {
      id: "basicInfo",
      name: "Basic Info",
    },
    {
      id: "links",
      name: "Links",
    },
    {
      id: "experience",
      name: "Experience",
    },
    {
      id: "education",
      name: "Education",
    },
    {
      id: "availability",
      name: "Availability & Resume",
    },
  ];

  const setUserProfileFormData = (data) => {
    console.log("THe url insdie setUserProfileFormData at Profile.js" + data);
    setUserData((prevValue) => ({ ...prevValue, profilePhoto: data }));
  };

  return (
    <div className="UserEntireProfilePage">
      <div className="talentUpperSection">
        {btns.map((btn) => (
          <button
            disabled={isDisabled && btn.id !== "basicInfo"}
            key={btn.id}
            name={btn.id}
            className={`btn ${activeBtn === btn.id ? "selectedBtn" : ""}`}
            onClick={(e) => setActiveBtn(e.target.name)}
          >
            {btn.name}
          </button>
        ))}
      </div>

      <div>
        {activeBtn === "basicInfo" ? (
          <BasicInfo
            userData={userData}
            setUserData={setUserData}
            query={query}
            onsubmitForm={onsubmitForm}
            setUserProfileFormData={setUserProfileFormData}
            currentProfileData={currentProfileData}
            setCurrentProfileData={setCurrentProfileData}
            setMarketersdata={setMarketersdata}
          />
        ) : activeBtn === "links" ? (
          <Links socialLinks={socialLinks} setMarketersdata={setMarketersdata} />
        ) : activeBtn === "experience" ? (
          <Experience userData={userData} caseStudies={caseStudies} setMarketersdata={setMarketersdata}/>
        ) : activeBtn === "education" ? (
          <Education userData={userData} setMarketersdata={setMarketersdata}></Education>
        ) : (
          <Availability
            userData={userData}
            setUserData={setUserData}
            currentProfileData={currentProfileData}
            onsubmitForm={onsubmitForm}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
