import { PieChart } from '@material-ui/icons'
import React from 'react'

export const DropdownContentArea = (props) => {
const mainClass = {
    backgroundColor: "#F7F7F7",
    borderRadius: "20px",
    padding : props?.padding || "30px 50px",
    height : props?.height || "max-content",
    width : "100%",
    textAlign: "left",
    marginBottom : "10px"

}
  return (
    <div style={mainClass}>
        {props.children}
    </div>
  )
}
