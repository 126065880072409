import React, { useState, useEffect } from "react";
import "./SingleSession.css";
import arrowIcon from "../img/arrow_back_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import hourGlass from "../img/hourglass_bottom_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import SlotSelect from "./slotselect";
import mobileArrow from "../img/chevron_left_40dp_FILL0_wght400_GRAD0_opsz40.svg";
import ratingstar from "../img/star_24dp_FILL0_wght400_GRAD0_opsz24.svg";
import axios from "../../../../../axios.js";
import { IoIosAdd, IoIosStar, IoIosStarOutline } from "react-icons/io";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

export default function SingleSession({ sessionDetails, handleHideSession }) {
  const history = useHistory();
  const [isBooking, setIsBooking] = useState(false);


  const [sessionReviews, setSessionReviews] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useState(() => {
    (async () => {
      const { data } = await axios.get(
        `/mentoring/get-session-reviews/${sessionDetails?._id}`,
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      console.log("The data is ", data);
      if (data) {
        setSessionReviews(data);
      }
    })();
  });

  const handleBookSession = () => {
    setIsBooking(true);
  };

  if (isBooking) {
    return (
      <SlotSelect sessionDetails={sessionDetails} setIsBooking={setIsBooking} />
    );
  }

  return (
    <div className="userProfileSingleSession">
      <div className="sessionHeader">
        <div className="sessionHeader-title">
          <button
            className="backButton"
            onClick={() => handleHideSession(sessionDetails?._id)}
          >
            <img src={arrowIcon} alt="left arrow" />
          </button>
          <div className="session-mobile">
            <h1 className="sessionHeader-title heading">
              {sessionDetails?.title}
            </h1>
            <div className="mobile-icon">
              <img src={mobileArrow} />
            </div>
          </div>
        </div>

        <div className="duration">
          <span>
            <img src={hourGlass} alt="hourglass" /> {sessionDetails?.duration}{" "}
            minutes
          </span>
        </div>
      </div>
      <div className="sessionContent">
        <div className="duration-mob">
          <span>
            <img src={hourGlass} alt="hourglass" /> {sessionDetails?.duration}{" "}
            minutes
          </span>
        </div>
        <div className="sessionDetails">{sessionDetails?.description}</div>

        {/* Profile Card */}
        <div className="sessionProfile">
          {sessionReviews?.map((element) => {
            return (
              <div className="profileCard">
                <div className="reviewRating">
                  {[...Array(5)].map((star, index) => (
                    <span key={index} className="star">
                      {index < element?.rating ? (
                        <IoIosStar
                          style={{
                            color: "black",
                          }}
                        />
                      ) : (
                        <IoIosStarOutline
                          style={{
                            color: "black",
                          }}
                        />
                      )}
                    </span>
                  ))}
                </div>
                <p>
                  {element?.review}
                </p>
                <div className="profileInfo">
                  <img
                    src={element?.reviewer?.profilePhoto}
                    alt="Profile"
                    className="profileImage"
                  />
                  <p>{element?.reviewer?.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="sessionFooter">
        <div className="price">
          <span>&#8377; {sessionDetails?.price}</span>
        </div>
        <button className="bookButton" onClick={handleBookSession}>
          Book Session
        </button>
      </div>
    </div>
  );
}
