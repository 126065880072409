import React from "react";
import { MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { BsArrowDownLeft, BsArrowUpRight } from "react-icons/bs";
import { CiChat1 } from "react-icons/ci";
import { MdOutlineThumbsUpDown } from "react-icons/md";
import "./RequestingUnlock.css";

import { FaInfo } from "react-icons/fa6";
import TwoButtonWrapper from "../../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import LinkedInLogo from "../demoImages/linkedin.png";

export const RequestingUnlock = () => {
  const userData = [
    {
      name: "Mahendra Soni",
      email: "mahi25soni@gmail.com",
    },
    {
      name: "Mahendra Soni",
      email: "mahi25soni@gmail.com",
    },
    {
      name: "Mahendra Soni",
      email: "mahi25soni@gmail.com",
    },
    {
      name: "Mahendra Soni",
      email: "mahi25soni@gmail.com",
    },
  ];
  return (
    <div className="requestingUnlockContainer">
      {userData?.map((user) => {
        return (
          <div className="requestColumn">
            <div className="candidateInformation">
              <div className="request-header-prof">
                <div className="profile-img"></div>
                <p>{user?.name}</p>
              </div>

              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "400",
                }}
              >
                from
              </p>

              <div className="request-header-prof">
                <div className="profile-img"></div>
                <p>{user?.name}</p>
              </div>
            </div>

            <div className="selectButtons">
              <TwoButtonWrapper
                firstComponent={
                  <img src={LinkedInLogo} className="icon20size" />
                }
                backgroundColor={"white"}
                color={"black"}
              />

              <TwoButtonWrapper
                firstComponent={<MdOutlineDone />}
                backgroundColor={"white"}
                color={"black"}
              />

              <TwoButtonWrapper
                firstComponent={<RxCross2 />}
                backgroundColor={"white"}
                color={"black"}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};
