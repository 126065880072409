import React, { useEffect, useState } from "react";
import { ChatBoxComponent } from "../../../../atoms/ChatBoxComponent/ChatBoxComponent";
import "./TalentPoolMessage.css";
import DefaultProfile from "../../img/profile.png";
import axios from "../../../../axios";
import moment from "moment-timezone";

export const TalentPoolMessage = () => {
  const [allChatUsers, setAllChatUsers] = useState([]);
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedChatBoxId, setSelectedChatBoxId] = useState(null);
  const [idToProfile, setIdToProfile] = useState({});

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        "/talentPool/get-all-talent-pool-message/",
        {
          headers: {
            Authorization: `Bearer ${Marketer?.token}`,
          },
        }
      );

      setAllChatUsers(data?.data);
    })();
  }, []);

  const handleUserToChat = async (chatInfo) => {
    console.log("chatInfo", chatInfo);
    setSelectedChatBoxId(chatInfo?._id);
    setIdToProfile({
      [chatInfo?.user_id?._id]: chatInfo?.user_id?.profilePhoto,
      [chatInfo?.pool_owner?._id]: chatInfo?.pool_owner?.profilePhoto
    })
    const { data } = await axios.get(
      `/talentPool/chat/get_messages/${chatInfo?._id}`,
      {
        headers: { authorization: "Bearer " + Marketer.token },
      }
    );

    setChatMessages(data?.data);
  };

  console.log("id to profile", idToProfile);
  return (
    <div className="TalentPoolMessagePage">
      <div className="userChatSideBar">
        {allChatUsers?.map((chatInfo, index) => {
          return (
            <>
              {chatInfo?.pool_owner?._id === Marketer?.user?._id ? (
                <div
                  className="chatOuterHeader"
                  onClick={() => handleUserToChat(chatInfo)}
                >
                  <div className="userInfo">
                    <img
                      src={chatInfo?.user_id?.profilePhoto}
                      alt=""
                      className="userImage"
                    />
                    <div>
                      <p
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        {chatInfo?.user_id?.name}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {chatInfo?.unseenCounts?.pool_owner === 0
                          ? chatInfo?.last_message?.message
                          : `${chatInfo?.unseenCounts?.pool_owner} new messages`}
                      </p>
                    </div>
                  </div>

                  <p className="lastMessageTime">
                    {moment(chatInfo?.last_message?.createdAt).format(
                      "hh:mm A"
                    )}
                  </p>
                </div>
              ) : (
                <div
                  className="chatOuterHeader"
                  onClick={() => handleUserToChat(chatInfo)}
                >
                  <div className="userInfo">
                    <img
                      src={chatInfo?.pool_owner?.profilePhoto}
                      alt=""
                      className="userImage"
                    />
                    <div>
                      <p
                        style={{
                          fontSize: "18px",
                        }}
                      >
                        {chatInfo?.pool_owner?.name}
                      </p>
                      <p
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {chatInfo?.unseenCounts?.user_id === 0
                          ? chatInfo?.last_message?.message
                          : `${chatInfo?.unseenCounts?.user_id} new messages`}
                      </p>
                    </div>
                  </div>

                  <p className="lastMessageTime">
                    {moment(chatInfo?.last_message?.createdAt).format(
                      "hh:mm A"
                    )}
                  </p>
                </div>
              )}
            </>
          );
        })}
      </div>
      {selectedChatBoxId ? (
        <div className="chatSide">
          <ChatBoxComponent
            height={"calc(100vh - 200px)"}
            chatMessages={chatMessages}
            setChatMessages={setChatMessages}
            chatBoxId={selectedChatBoxId}
            type={"talentPool"}
            idToProfile={idToProfile}
          />
        </div>
      ) : null}
    </div>
  );
};
