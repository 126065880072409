import React, { useState } from "react";
import "./TwoButtonWrapper.css";

const TwoButtonWrapper = ({
  firstComponent,
  secondComponent,
  firstComponentFunction,
  secondComponentFunction,
  componentFunction,
  backgroundColor,
  color,
  borderColor,
  hoverActivate,
  ...props
}) => {

  const [hover, setHover] = useState(false);
  const mainClass = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    height: props?.height || "60px",
    padding: props?.padding || "0 20px",
    backgroundColor: backgroundColor || "black",
    color: color || "white",
    border: "1px solid",
    borderColor: borderColor || "#E0E0E0",
    borderRadius: props?.borderRadius || "20px",
    fontSize: props?.fontSize || "18px",
    cursor: "pointer",
    width: "max-content",
  };

  return (
    <div
      style={mainClass}
      onClick={componentFunction}
      onMouseEnter={props?.onMouseEnter}
      onMouseLeave={props?.onMouseLeave || (() => setHover(false))}
    >
      {hoverActivate ? (
        <>
          <div
            onClick={firstComponentFunction}
            onMouseEnter={() => setHover(true)}
          >
            {firstComponent}
          </div>

          {hover && (
            <div onClick={secondComponentFunction}>{secondComponent}</div>
          )}
        </>
      ) : (
        <>
          <div onClick={firstComponentFunction}>{firstComponent}</div>
          {secondComponent && (
            <div onClick={secondComponentFunction}>{secondComponent}</div>
          )}
        </>
      )}
    </div>
  );
};

export default TwoButtonWrapper;
