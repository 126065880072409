import React, { useEffect, useState } from "react";
import TwoButtonWrapper from "../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { RxCross2 } from "react-icons/rx";
import "./ApplyToTalentPoolPage.css";
import axios from "../../../axios.js";
import { toast } from "react-toastify";
import toastStyle from "../../../assets/toastConfig.js"

export const ApplyToTalentPoolPage = ({ user, setApplyToTalentPoolPage }) => {
  console.log("user", user);
  const [talentPoolData, setTalentPoolData] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/talentPool/get_talent_pool_categories/${user._id}`
      );
      setTalentPoolData(data);
    })();
  }, []);

  const sendAdmissionRequest = async (category_id) => {
    const { data } = await axios.post("/talentPool/admission-request/", {
      user_id: Marketer?.user?._id,
      category_id: category_id,
      pool_owner: user?._id,
    });

    if(data?.success){
      toast.success(data?.message, toastStyle);
    }
    else{
      toast.error(data?.message, toastStyle);
    }


  };
  return (
    <div className="ApplyToTalentPoolPage">
      <div className="pageHeadingLine">
        <p>Apply to talent pool</p>
        <TwoButtonWrapper
          firstComponent={<RxCross2></RxCross2>}
          backgroundColor={"white"}
          color={"black"}
          componentFunction={() => setApplyToTalentPoolPage(false)}
        />
      </div>

      <div className="disclaimer">
        <p>
        Select the talent pools you want to apply to.
        The mentor will be notified and will review your profile before letting you in on his talent pools
        </p>
        <div className="removeDisclaimer">
          <RxCross2></RxCross2>
        </div>
      </div>
      <div className="entirePoolList">
        {talentPoolData?.map((element) => {
          return (
            <div className="categoryInfo">
              <div className="pool-data-header">
                <p>
                  {element?.name} | <span>{element?.user_count}</span>
                </p>
              </div>

              <TwoButtonWrapper
                firstComponent={"Apply"}
                componentFunction={() => sendAdmissionRequest(element?._id)}
                height={"45px"}
                borderRadius={"10px"}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
