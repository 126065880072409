import React, { useState, useEffect, useRef } from "react";
import "./Education.css";
import DateSection from "../Experience/DateSection/DateSection";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import { ToastContainer, toast } from "react-toastify";
import axios from "../../../../axios.js";
import toastStyle from "../../../../assets/toastConfig.js";

export const Education = ({ userData, setMarketersdata }) => {
  const Marketer = JSON.parse(localStorage.getItem("marketer"));
  const [educationArray, setEducationArray] = useState([]);
  const [isAddEducationOpen, setIsAddEducationOpen] = useState(false);
  const [currentlyWorking, setCurrentlyWorking] = useState(false);
  const [editDeleteDropdown, setEditDeleteDropdown] = useState({});
  const [toEdit, setToEdit] = useState(null);

  const defaultEducationDetials = {
    collegeName: "",
    collegeWebsite: "",
    collegeDegree: "",
    fieldOfStudy: "",
    grade: "",
    aboutCollege: "",
    dates: {
      from: {
        month: "",
        year: "",
      },
      till: {
        month: "",
        year: "",
      },
    },
    currently_pursuing: false,
  };
  const [newEducationDetails, setNewEducationDetails] = useState(
    defaultEducationDetials
  );

  const mainContainerRef = useRef();

  useEffect(() => {
    if (isAddEducationOpen) {
      mainContainerRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [isAddEducationOpen]);

  useEffect(() => {
    setEducationArray([...userData?.education]);
  }, [userData?.education]);

  function handleAddNewDatails(e) {
    setNewEducationDetails({
      ...newEducationDetails,
      [e.target.name]: e.target.value,
    });
  }

  async function handleSubmit(e) {
    try {
      let { data } = await axios.post(
        "/marketers/updateMarketerEducation",
        newEducationDetails,
        {
          headers: {
            Authorization: "Bearer " + Marketer.token,
          },
        }
      );

      toast.success("Education Added Successfully", toastStyle);
      closeForm();
      setEducationArray(data?.data);
      setMarketersdata({ ...userData, education: data?.data });
    } catch (error) {
      console.log(error);
      //   setIsError(true);
      // toast.error("Please fill all the fields");
    }
  }

  function handleSaveExp(e) {
    handleSubmit(e);
    console.log("the new details are ", newEducationDetails);
  }

  function handleSelectedDates(value) {
    setNewEducationDetails({ ...newEducationDetails, dates: value });
  }

  async function handleDeleteEducation(id) {
    const { data } = await axios.delete(`/marketers/deleteEducation/${id}`, {
      headers: {
        Authorization: "Bearer " + Marketer.token,
      },
    });

    toast.success("Education Deleted Successfully", toastStyle);
    setEducationArray(data?.data);
    setMarketersdata({ ...userData, education: data?.data });
  }

  async function handleEditEducation(id) {
    const { data } = await axios.post(
      `/marketers/editEducation/${id}`,
      newEducationDetails,
      {
        headers: {
          Authorization: "Bearer " + Marketer.token,
        },
      }
    );
    setEducationArray(data?.data);
    setMarketersdata({ ...userData, education: data?.data });
    toast.success("Education Updated Successfully", toastStyle);
    closeForm();
  }

  function handleEducationDropdown(id) {
    setEditDeleteDropdown({
      ...editDeleteDropdown,
      [id]: !editDeleteDropdown[id],
    });
  }

  function openEditComponent(id, experience) {
    setNewEducationDetails(experience);
    setToEdit(id);
    setEditDeleteDropdown({ [id]: false });
    setIsAddEducationOpen(true);
  }

  function closeForm() {
    setIsAddEducationOpen(false);
    setNewEducationDetails(defaultEducationDetials);
  }

  function handleCurrentlyWorkingChange(e) {
    setNewEducationDetails({
      ...newEducationDetails,
      currently_pursuing: e.target.checked,
    });
  }

  console.log("the education details are ", newEducationDetails);
  return (
    <div className="education_main_container">
      {educationArray.length > 0 ? (
        educationArray.map((educationElement) => {
          return (
            <div className="eachCollegeExperience">
              <div className="writtenExperience">
                <div className="education-item-header">
                  <div className="education-item-title">
                    {educationElement?.collegeDegree}

                    <div className="editDeleteComponent">
                      <span
                        class="material-symbols-outlined"
                        onClick={() =>
                          handleEducationDropdown(educationElement?._id)
                        }
                      >
                        more_vert
                      </span>

                      {editDeleteDropdown[educationElement?._id] && (
                        <div className="editDeleteButtons">
                          <button
                            onClick={() =>
                              openEditComponent(
                                educationElement?._id,
                                educationElement
                              )
                            }
                          >
                            Edit
                          </button>
                          <button
                            onClick={() =>
                              handleDeleteEducation(educationElement?._id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="education-item-company">
                    {educationElement?.collegeName} |{" "}
                    {educationElement?.fieldOfStudy}
                  </div>
                  <div className="education-item-duration">
                    {educationElement?.dates?.from?.month}{" "}
                    {educationElement?.dates?.from?.year} -{" "}
                    {educationElement?.currently_pursuing
                      ? "Present"
                      : educationElement?.dates?.till?.month +
                        " " +
                        educationElement?.dates?.till?.year}
                  </div>
                </div>
                <div className="education-item-description">
                  {educationElement?.aboutCollege}
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="eachCollegeExperience">
          Add your education to add credibility to your profile
        </div>
      )}

      {!isAddEducationOpen && (
        <div className="theAddButtonLine">
          <TwoButtonWrapper
            firstComponent={"Add new education"}
            height={"45px"}
            borderRadius={"10px"}
            componentFunction={() => setIsAddEducationOpen(true)}
          />
        </div>
      )}

      <form className="education_form formScrollBarDisplay">
        <div
          className="innerExperienceSection"
          style={
            !isAddEducationOpen
              ? { paddingBottom: "130px" }
              : { paddingBottom: "30px" }
          }
        ></div>

        {isAddEducationOpen && (
          <div ref={mainContainerRef} className="mainContainer">
            <div className="educationDetails">
              <p className="label">
                College Name<sup>*</sup>
              </p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newEducationDetails?.collegeName}
                  onChange={(e) => {
                    setNewEducationDetails({
                      ...newEducationDetails,
                      collegeName: e.target.value,
                    });
                  }}
                  placeholder="Add college name"
                />
              </div>
            </div>

            {/* role */}
            <div style={{ width: "100%" }}>
              <p className="label">College Website</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newEducationDetails.collegeWebsite}
                  onChange={(e) =>
                    setNewEducationDetails({
                      ...newEducationDetails,
                      collegeWebsite: e.target.value,
                    })
                  }
                  placeholder="Select/Add website url"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">Degree</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newEducationDetails?.collegeDegree}
                  onChange={(e) => {
                    setNewEducationDetails({
                      ...newEducationDetails,
                      collegeDegree: e.target.value,
                    });
                  }}
                  placeholder="Select/Add your stream"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">Field of Study*</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newEducationDetails.fieldOfStudy}
                  onChange={(e) =>
                    setNewEducationDetails({
                      ...newEducationDetails,
                      fieldOfStudy: e.target.value,
                    })
                  }
                  placeholder="Select/Add your field"
                />
              </div>
            </div>
            <div className="educationFormDates">
              {/* from date */}
              <DateSection
                selectedDate={newEducationDetails?.dates}
                setSelectedDate={handleSelectedDates}
                dateType={"from"}
                currentlyWorking={newEducationDetails?.currently_pursuing}
              />

              {!newEducationDetails?.currently_pursuing && (
                <DateSection
                  selectedDate={newEducationDetails.dates}
                  setSelectedDate={handleSelectedDates}
                  dateType={"till"}
                  currentlyWorking={newEducationDetails?.currently_pursuing}
                />
              )}
            </div>
            <div className="checkBoxWrapper">
              <input
                type="checkbox"
                checked={newEducationDetails?.currently_pursuing}
                onChange={handleCurrentlyWorkingChange}
              />
              <span>Currently pursuing this education</span>
            </div>
            {/* about section */}
            <div style={{ width: "100%" }}>
              <p className="label">Grade*</p>
              <div className="inputWrapper">
                <input
                  type="text"
                  name=""
                  id=""
                  value={newEducationDetails.grade}
                  onChange={(e) =>
                    setNewEducationDetails({
                      ...newEducationDetails,
                      grade: e.target.value,
                    })
                  }
                  placeholder="Add your grades"
                />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <p className="label">About your time at college</p>
              <div className="inputWrapper textareaWrapper">
                <textarea
                  type="text"
                  name=""
                  id=""
                  rows="6"
                  value={newEducationDetails.aboutCollege}
                  onChange={(e) =>
                    setNewEducationDetails({
                      ...newEducationDetails,
                      aboutCollege: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            {isAddEducationOpen && (
              <div className="newFormButtons">
                <TwoButtonWrapper
                  firstComponent={"Cancel"}
                  backgroundColor={"white"}
                  color={"black"}
                  padding={"5px 20px"}
                  height={"45px"}
                  borderRadius={"10px"}
                  componentFunction={() => closeForm()}
                ></TwoButtonWrapper>
                {toEdit ? (
                  <TwoButtonWrapper
                    firstComponent={"Update"}
                    padding={"5px 20px"}
                    height={"45px"}
                    borderRadius={"10px"}
                    componentFunction={() => handleEditEducation(toEdit)}
                  ></TwoButtonWrapper>
                ) : (
                  <TwoButtonWrapper
                    firstComponent={"Save"}
                    padding={"5px 20px"}
                    height={"45px"}
                    borderRadius={"10px"}
                    componentFunction={() => handleSaveExp()}
                  ></TwoButtonWrapper>
                )}
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
};
