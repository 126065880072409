import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';

const BreadCrumb = ({ breadCrumbNextRoute, setSelectedCompany }) => {
    const location = useLocation();
    let routing = '';
    const [route, setRoute] = useState(location.pathname.split("/"));
    const [selectedValue, setSelectedValue] = useState('');

    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (queryParams.has("selectedBrand")) {
            setSelectedValue(queryParams.get("selectedBrand"));
        } else if (queryParams.has("selectedAgency")) {
            setSelectedValue(queryParams.get("selectedAgency"));
        } else {
            console.log("No selected parameter found");
        }
    }, [location.search]);

    useEffect(() => {
        const userRoute= location.pathname.split("/")
        setRoute(userRoute);
        if (userRoute[2]?.endsWith('new-company-profiles') && !location.search) {
            setSelectedValue('');
            setSelectedCompany('');
        }
    }, [location]);

    

    return (
        <p style={{
            fontSize: '28px',
            color: 'black',
            fontWeight: '500',
        }}>
            {route.map((key, index) => {
                routing = routing + (index === 0 ? '' : '/') + key

                return (
                    (key !== '') && <span key={index}>
                        <Link to={routing} onClick={() => { console.log(routing, 'this is routing test') }}>
                            {(key === "user-dashboard")
                                ? "User Profile"
                                : (selectedValue ? breadCrumbNextRoute + ' > ' + selectedValue : breadCrumbNextRoute + ' ')
                            }
                        </Link>
                        {index !== route.length - 1 && index !== 0 && <span> {' > '}</span>}
                    </span>
                )
            })}
        </p>
    )
}

export default BreadCrumb