import React from "react";
import { Helmet } from "react-helmet";
import './ThankYouPage.css'
import handShake from './images/handshake.svg'
import topArrow from './images/arrow-top-right-thin.svg'
import { useLocation, useHistory } from "react-router-dom";

const ThankYouPage = () => {
    const history = useHistory();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const username = searchParams.get('username');
    const category = searchParams.get('category');

    console.log("the username is ", username);
    console.log("the category is ", category);

    function handleRedirect() {
        history.push(`/userProfile/${username}?category=${category}`);
    }

    return (
        <>
            <Helmet>
                <title>Thank You</title>
            </Helmet>

            <div className="thank_you_container">
                <div className='handshakeImg'>
                    <img src={handShake} alt="handshake" />
                </div>

                <h1>Thank you!</h1>

                <p>Your payment has been processed.
                    <br />
                    <span>You can go back to the profile and view the talent pool now.</span>
                </p>

                <button type="button" className="viewTalentPoolBtn" onClick={handleRedirect}>
                    <span>View Talent Pool</span>
                    <img src={topArrow} alt="top arrow" />
                </button>
            </div>
        </>
    );
};

export default ThankYouPage;
