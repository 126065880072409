import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./UserProfile.css";
import FirstCompo from "../UserProfileCompo/firstUserProfileCompo/firstUserProfileCompo";
import SecondCompo from "../UserProfileCompo/secondUserProfileCompo/secondUserProfileCompo";
import UserSection from "../UserProfileCompo/userSection/userSection";
import CaseStudyCard from "../CaseStudyListingComponents/CaseStudyCard/CaseStudyCard";
import UserPageContent from "../UserProfileCompo/UserPageContent.js/userPageContent.js";
import axios from "../../axios";
import Loader from "../loader/Loader";
import { ApplyToTalentPoolPage } from "../UserProfileCompo/ApplyToTalentPoolPage/ApplyToTalentPoolPage.jsx";
const App = () => {
  const username = useParams().username;
  const [userDetails, setUserDetail] = useState();
  const [caseStudies, setCaseStudies] = useState();
  const [applyToTalentPoolPage, setApplyToTalentPoolPage] = useState(false);
  console.log(caseStudies, "caseStudies");
  console.log(userDetails, "userDetails");

  useEffect(() => {
    const fetchData = async () => {
      const userProfileDetails = await axios.get(
        `/marketers/getMarketerByUsername/${username}`
      );
      const creditsResponse = await axios.get(
        `/credits/getMarketersCredits?email=${userProfileDetails.data.email}`
      );
      setUserDetail(userProfileDetails.data);
      setCaseStudies(creditsResponse.data);
    };
    fetchData();
  }, []);

  if (!userDetails && !caseStudies) {
    return (
      <div>
        <Loader />
      </div>
    );
  } else {
    console.log(userDetails.name);
    return (
      <div className="Userprofilecompo">
        {/* <FirstCompo key={data._id} data={data} />
        <SecondCompo key={data._id} username={username} data={data} /> */}
        <UserSection
          userData={{
            name: userDetails.name ? userDetails.name : "",
            username: userDetails.username ? userDetails.username : "",
            profilePhoto: userDetails.profilePhoto
              ? userDetails.profilePhoto
              : "https://unispade-s3.blr1.cdn.digitaloceanspaces.com/profilePhoto/profileimage.png",
          }}
          setApplyToTalentPoolPage = {setApplyToTalentPoolPage}
        />

        {applyToTalentPoolPage ? (
          <ApplyToTalentPoolPage
            user={userDetails}
            setApplyToTalentPoolPage={setApplyToTalentPoolPage}
          />
        ) : (
          <UserPageContent caseStudies={caseStudies} user={userDetails} />
        )}
      </div>
    );
  }
};
export default App;
