import React, { useState, useEffect } from "react";
import { RxCross2 } from "react-icons/rx";
import { CiChat1 } from "react-icons/ci";
import {
  MdDoneAll,
  MdOutlineWavingHand,
  MdOutlineDone,
  MdExpandMore,
  MdExpandLess,
} from "react-icons/md";
import { IoFunnelOutline } from "react-icons/io5";
import UserInfoSlate from "../../../../atoms/UserInfoSlate/UserInfoSlate";
import "./ApplicationActivity.css";
import TwoButtonWrapper from "../../../../atoms/TwoButtonWrapper/TwoButtonWrapper";
import axios from "../../../../axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import toastStyle from "../../../../assets/toastConfig";
import { DropdownContentArea } from "../../../../atoms/DropdownContentArea";
import { ChatBoxComponent } from "../../../../atoms/ChatBoxComponent/ChatBoxComponent";

const variousStatus = [
  {
    _id: 1,
    name: "PENDING_RESPONSE",
  },
  {
    _id: 2,
    name: "ADMITTED",
  },
  {
    _id: 3,
    name: "DECLINED",
  },
  {
    _id: 4,
    name: "ADD-INVITE",
  },
  {
    _id: 5,
    name: "ACTIVE",
  },
];

export const ApplicationActivity = () => {
  const history = useHistory();
  const [requests, setRequests] = useState([]);
  const [tempRequestData, setTempRequestData] = useState([]);
  const [providedFilterOptions, setProvidedFilterOptions] = useState();
  const [filterList, setFilterList] = useState([]);
  const [profileDropdown, setFilterDropdown] = useState({});
  const [deleteButtonId, setDeleteButtonId] = useState({});
  const [chatButtonSelected, setChatButtonSelected] = useState({});
  const [chatMessages, setChatMessages] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const [idToProfile, setIdToProfile] = useState({
    [Marketer?.user?._id]: Marketer?.user?.profilePhoto,
  });

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `/userActivity/get-application-user-activity/${Marketer?.user?._id}`,
        {
          headers: {
            authorization: `Bearer ${Marketer.token}`,
          },
        }
      );

      setRequests(data?.data?.list);
      setTempRequestData(data?.data?.list);
      setProvidedFilterOptions(data?.data?.filters);
    })();
  }, []);

  const handleDeleteButton = (id) => {
    if (!deleteButtonId[id]) {
      setChatButtonSelected({ [id]: false });
    }
    setDeleteButtonId({ [id]: !deleteButtonId[id] });
  };

  const handleChatButton = (id, ownerData,  chatBoxId) => {
    if (!chatButtonSelected[id]) {
      setIdToProfile((prev) => ({
        ...prev, 
        [ownerData?._id]: ownerData?.profilePhoto
      }))
      setDeleteButtonId({ [id]: false });
      getAllMessage(chatBoxId);

    }
    setChatButtonSelected({ [id]: !chatButtonSelected[id] });
  };

  const getAllMessage = async (id) => {
    const { data } = await axios.get(`/talentPool/chat/get_messages/${id}`, {
      headers: { authorization: "Bearer " + Marketer.token },
    });

    setChatMessages(data?.data);
  };

  useEffect(() => {
    if (filterList.length === 0) {
      setTempRequestData(requests);
    } else {
      const filteredData = applyFilters(requests, filterList);
      setTempRequestData(filteredData);
    }
  }, [filterList]);

  const handleFilterDropdown = (filter) => {
    setFilterDropdown({
      [filter]: !profileDropdown[filter],
    });
  };
  const addFilter = (filterData, type) => {
    setFilterList((prevFilters) => [...prevFilters, { filterData, type }]);
    setFilterDropdown({});
  };
  const removeFilter = (filter_id) => {
    setFilterList((prevFilters) =>
      prevFilters.filter((item) => item.filterData._id !== filter_id)
    );
  };

  const applyFilters = (data, filters) => {
    let filteredData = [];

    filters.forEach(({ filterData: filterValue, type }) => {
      let nothing;
      if (type === "PoolCategory") {
        nothing = data.filter(
          (element) => element?.category_id?._id === filterValue?._id
        );
      } else if (type === "PoolOwner") {
        nothing = data.filter(
          (element) => element?.pool_owner?._id === filterValue?._id
        );
      } else if (type === "Status") {
        nothing = data.filter(
          (element) => element?.current_status === filterValue?.name
        );
      }
      filteredData = [...filteredData, ...nothing];
    });

    return filteredData;
  };

  const handlePoolOwnerRedirect = (username) => {
    history.push(`/userprofile/${username}`);
  };

  const handleAddUserToPool = async (id, userId, consent) => {
    try {
      const { data } = await axios.post(
        `/talentPool/talent_pool_user_consent`,
        {
          poolUserId: id,
          consent: consent,
          userId : userId
        },
        {
          headers: {
            authorization: `Bearer ${Marketer.token}`,
          },
        }
      );

      if (data?.success) {
        toast.success(data?.message, toastStyle);
        if (data?.data) {
          const newTempData = tempRequestData?.map((request) => {
            if (request?._id === id) {
              return data?.data;
            }
            return request;
          });
          setTempRequestData(newTempData);
        } else {
          const newTempData = tempRequestData?.filter(
            (request) => request?._id !== id
          );
          setTempRequestData(newTempData);
        }
      } else {
        toast.error(data?.message, toastStyle);
      }
    } catch (err) {
      console.log("Error in handleAddUserToPool ", err);
    }
  };

  async function handleDeleteAddedUser(id) {
    let { data } = await axios.delete(
      `/talentPool/delete_talent_pool_users/${id}`,
      { headers: { authorization: "Bearer " + Marketer.token } }
    );

    if (data?.success) {
      toast.success(data?.message, toastStyle);
      const newTempData = tempRequestData?.filter(
        (request) => request?._id !== id
      );
      setTempRequestData(newTempData);
    }
  }

  return (
    <div className="ApplicationActivityTab">
      <div className="filterSection">
        <div className="filterTab">
          <div className="filterIcon">
            <IoFunnelOutline />
            <p>Filtered By</p>
          </div>

          <div className="filteringOption">
            <div className="filterTabDropdown">
              <p>Pool Category</p>
              <div onClick={() => handleFilterDropdown("PoolCategory")}>
                {profileDropdown["PoolCategory"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>
              {profileDropdown["PoolCategory"] && (
                <div className="variousOptions">
                  {providedFilterOptions["PoolCategory"]?.map((category) => {
                    return (
                      <div onClick={() => addFilter(category, "PoolCategory")}>
                        {category?.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="filterTabDropdown">
              <p>Pool Owner</p>
              <div onClick={() => handleFilterDropdown("PoolOwner")}>
                {profileDropdown["PoolOwner"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>
              {profileDropdown["PoolOwner"] && (
                <div className="variousOptions">
                  {providedFilterOptions["PoolOwner"]?.map((owner) => {
                    return (
                      <div onClick={() => addFilter(owner, "PoolOwner")}>
                        {owner?.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="filterTabDropdown">
              <p>Request Status</p>
              <div onClick={() => handleFilterDropdown("Status")}>
                {profileDropdown["Status"] ? (
                  <MdExpandLess />
                ) : (
                  <MdExpandMore />
                )}
              </div>
              {profileDropdown["Status"] && (
                <div className="variousOptions">
                  {variousStatus?.map((status) => {
                    return (
                      <div onClick={() => addFilter(status, "Status")}>
                        {status?.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          {filterList?.length > 0 && (
            <>
              <div></div>
              <div className="selectedFilters">
                {filterList?.map((filter) => {
                  return (
                    <div className={`singleFilter ${filter?.type}`}>
                      {filter?.filterData?.name}
                      <RxCross2
                        className="crossIcon"
                        onClick={() => removeFilter(filter?.filterData?._id)}
                      ></RxCross2>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>

      <div className="applicationActivityArea">
        {tempRequestData?.map((request, index) => {
          return (
            <>
              <div className="oneRequestSent">
                <div className="aboutPool">
                  <p>{request?.category_id?.name}</p>
                  <UserInfoSlate
                    user_name={request?.pool_owner?.name}
                    height="45px"
                    imageSize="30px"
                    image_url={request?.pool_owner?.profilePhoto}
                    componentFunction={() =>
                      handlePoolOwnerRedirect(request?.pool_owner?.username)
                    }
                  />
                </div>

                {request?.current_status === "PENDING_RESPONSE" ? (
                  <TwoButtonWrapper
                    firstComponent={"Pending Response"}
                    backgroundColor={"#FFFB95"}
                    borderColor={"#E9E000"}
                    color={"black"}
                  />
                ) : request?.current_status === "DECLINED" ? (
                  <div className="statusBar">
                    <TwoButtonWrapper
                      firstComponent={"Declined"}
                      backgroundColor={"#FFA8A8"}
                      borderColor={"#DD0000"}
                      color={"black"}
                    />
                    <TwoButtonWrapper
                      firstComponent={
                        <span className="material-symbols-outlined">close</span>
                      }
                      backgroundColor={"white"}
                      // borderColor={""}
                      color={"black"}
                    />
                  </div>
                ) : request?.current_status === "ADD-INVITE" ? (
                  <div className="statusBar">
                    <TwoButtonWrapper
                      firstComponent={"Invited"}
                      backgroundColor={"#BACDFF"}
                      borderColor={"#5182FF"}
                      color={"black"}
                    />
                    <TwoButtonWrapper
                      firstComponent={
                        <span className="material-symbols-outlined">check</span>
                      }
                      backgroundColor={"white"}
                      color={"black"}
                      componentFunction={() =>
                        handleAddUserToPool(request?._id, request?.user_id, true)
                      }
                    />
                    <TwoButtonWrapper
                      firstComponent={
                        <span className="material-symbols-outlined">close</span>
                      }
                      backgroundColor={"white"}
                      color={"black"}
                      componentFunction={() =>
                        handleAddUserToPool(request?._id,request?.user_id, false)
                      }
                    />
                  </div>
                ) : request?.current_status === "ACTIVE" ? (
                  <div className="statusBar">
                    <TwoButtonWrapper
                      firstComponent={"Active"}
                      backgroundColor={"#74FF5E"}
                      borderColor={"#40C800"}
                      color={"black"}
                    />

                    <TwoButtonWrapper
                      firstComponent={
                        <span className="material-symbols-outlined">chat</span>
                      }
                      backgroundColor={`${
                        chatButtonSelected[request?._id] ? "#F0F0F0" : "white"
                      }`}
                      color={"black"}
                      componentFunction={() =>
                        handleChatButton(request?._id, request?.pool_owner,  request?.chat_inbox)
                      }
                    />
                    <TwoButtonWrapper
                      firstComponent={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                      backgroundColor={`${
                        deleteButtonId[request?._id] ? "#F0F0F0" : "white"
                      }`}
                      color={"black"}
                      componentFunction={() => handleDeleteButton(request?._id)}
                    />
                  </div>
                ) : null}
              </div>

              {deleteButtonId[request?._id] && (
                <div className="deleteDropdownComponent">
                  <DropdownContentArea padding={"20px 20px"} height={"60px"}>
                    <p>
                        Are you sure you want to remove this yourself from this talent pool?
                    </p>
                  </DropdownContentArea>
                  <TwoButtonWrapper
                    firstComponent={"Remove"}
                    componentFunction={() =>
                      handleDeleteAddedUser(request?._id)
                    }
                  ></TwoButtonWrapper>
                </div>
              )}

              {chatButtonSelected[request?._id] && (
                <ChatBoxComponent
                  chatMessages={chatMessages}
                  setChatMessages={setChatMessages}
                  user_id={Marketer?.user?._id}
                  chatBoxId={request?.chat_inbox}
                  type={"talentPool"}
                  idToProfile={idToProfile}
                ></ChatBoxComponent>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
};
