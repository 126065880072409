import React, { useState } from "react";
import { RequestingAdmission } from "./RequestingAdmission/RequestingAdmission.jsx";
import { RequestingUnlock } from "./RequestingUnlock/RequestingUnlock.jsx";
import { InThePool } from "./InThePool/InThePool.jsx";

const TalentPool = ({ selectedCategory, setActiveBtn }) => {
  const [talentPoolTab, setTalentPoolTab] = useState("InThePool");

  const talentPoolEntireTabs = [
    {
      name: "In the pool",
      key: "InThePool",
    },
    {
      name: "Requesting admission",
      key: "RequestingAdmission",
    },
    {
      name: "Requesting unlock",
      key: "RequestingUnlock",
    },
  ];

  const handleTalentPoolTab = (tab) => {
    setTalentPoolTab(tab);
  };

  return (
    <>
      <div className="talentPoolPage">
        <div className="flexBetweenRadius10 width-full">
          <p onClick={() => setActiveBtn("poolCategory")}>
            {selectedCategory?.name}
          </p>
          <p>{selectedCategory?.price}</p>
        </div>

        <div className="talentPoolTabs">
          {talentPoolEntireTabs?.map((tab) => {
            return (
              <div
                className={`flexBetweenRadius10 ${
                  tab?.key === talentPoolTab ? "" : "deactiveTab"
                }`}
                onClick={() => handleTalentPoolTab(tab?.key)}
              >
                {tab?.name}
              </div>
            );
          })}
        </div>

        {talentPoolTab === "InThePool" ? (
          <InThePool selectedCategory={selectedCategory} />
        ) : talentPoolTab === "RequestingAdmission" ? (
          <RequestingAdmission selectedCategory={selectedCategory} />
        ) : talentPoolTab === "RequestingUnlock" ? (
          <RequestingUnlock />
        ) : null}
      </div>
    </>
  );
};

export default TalentPool;
