import React, { useEffect, useState, useRef } from "react";
import { IoMdTimer } from "react-icons/io";
import { MdOutlineMessage, MdOutlineDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FaInfo } from "react-icons/fa6";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Upcoming.css";
import axios from "../../../../../axios.js";
import moment from "moment";
import NewLoader from "../../../../loader/NewLoader/NewLoader.js";
import { toast } from "react-toastify";

// useIsMounted Hook
const useIsMounted = () => {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return isMounted;
};

const toast_style = {
  position: "top-right",
  autoClose: 4000,
  pauseOnHover: true,
  draggable: true,
  theme: "light",
  width: "10rem",
};

function Upcoming() {
  const [loader, setLoader] = useState(true);
  const [upcomingSessions, setUpcomingSession] = useState([]);
  const [visibleDetailsId, setVisibleDetailsId] = useState(null);
  const [visibleRescheduleId, setVisibleRescheduleId] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [timeSlots, setTimeSlots] = useState([]);
  const [requestBody, setRequestBody] = useState({});
  const [currentOpenedRequest, setCurrentOpenedRequest] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  const [maxBookingDays, setMaxBookingDays] = useState(null);
  const [noticePeriod, setNoticePeriod] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);

  const Marketer = JSON.parse(localStorage.getItem("marketer"));

  const isMounted = useIsMounted();

  const toast_style = {
    position: "top-right",
    autoClose: 4000,
    draggable: true,
    theme: "light",
    width: "10rem",
  };

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        "/mentoring/get-accepted-appointment-request/",
        {
          headers: {
            authorization: "Bearer " + Marketer.token,
          },
        }
      );

      console.log("The data is ", data);
      if (isMounted.current) {
        setUpcomingSession(data);
        setLoader(false);
      }
    })();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (selectedDate && currentOpenedRequest) {
  //       const { data } = await axios.get(
  //         `/mentoring/get-availability-by-date/${selectedDate}/${currentOpenedRequest.duration}/${Marketer.user._id}`,
  //         {
  //           headers: {
  //             authorization: "Bearer " + Marketer.token,
  //           },
  //         }
  //       );
  //       if (isMounted.current && data) {
  //         setTimeSlots(data);
  //       }
  //     }
  //   })();
  // }, [selectedDate, currentOpenedRequest]);

  // useEffect(() => {
  //   (async () => {
  //     if (currentOpenedRequest) {
  //       const requestMentorId =
  //         currentOpenedRequest?.mentor_id?._id ||
  //         currentOpenedRequest?.mentor_id;

  //       console.log("mentor id at set available days ", requestMentorId);

  //       const { data } = await axios.get(
  //         `/mentoring/get-general-availability/${requestMentorId}`,
  //         {
  //           headers: {
  //             authorization: "Bearer " + Marketer.token,
  //           },
  //         }
  //       );
  //       const the_days = data?.map((element) => element?.week_day);

  //       console.log("The days ", the_days);
  //       if (isMounted.current) {
  //         setAvailableDays(the_days);
  //       }
  //     }
  //   })();
  // }, [currentOpenedRequest]);

  // useEffect(() => {
  //   (async () => {
  //     if (currentOpenedRequest) {
  //       const requestMentorId =
  //         currentOpenedRequest?.mentor_id?._id ||
  //         currentOpenedRequest?.mentor_id;
  //       const { data: conditionData } = await axios.get(
  //         `/mentoring/get-availability-conditions/${requestMentorId}`,
  //         {
  //           headers: {
  //             authorization: "Bearer " + Marketer.token,
  //           },
  //         }
  //       );

  //       console.log("The conditional data is ", conditionData);
  //       if (isMounted.current) {
  //         setMaxBookingDays(conditionData?.maximum_booking_period);
  //         setNoticePeriod(conditionData?.minimum_notice_period);
  //         setSelectedDate(currentOpenedRequest?.date);
  //       }
  //     }
  //   })();
  // }, [currentOpenedRequest]);

  const handleTimeSlotSelect = (slotInfo) => {
    setSelectedTimeSlot(`${slotInfo.startTime} - ${slotInfo.endTime}`);

    console.log("The time info is", slotInfo);
    console.log("the selecte date is ", selectedDate);
    setRequestBody((prevData) => ({
      ...prevData,
      date: selectedDate,
      startTime: slotInfo.startTime,
      endTime: slotInfo.endTime,
      duration: slotInfo.duration,
    }));
  };

  const toggleDetails = (id) => {
    if (visibleDetailsId === id) {
      setVisibleDetailsId(null); // Toggle off if already visible
    } else {
      setVisibleDetailsId(id);
      setVisibleRescheduleId(null); // Close reschedule section if open
    }
  };

  const toggleReschedule = (id) => {
    if (visibleRescheduleId === id) {
      setVisibleRescheduleId(null); // Toggle off if already visible
    } else {
      setVisibleRescheduleId(id);
      setVisibleDetailsId(null);
      upcomingSessions?.map((element) => {
        if (element._id === id) {
          setCurrentOpenedRequest(element);
        }
      }); // Close details section if open
    }
  };

  const handleReschedule = async (requestId) => {
    const { data } = await axios.post(
      `mentoring/reschedule-appointment/${requestId}`,
      requestBody,
      {
        headers: {
          authorization: "Bearer " + Marketer.token,
        },
      }
    );
    if (data.success === false) {
      toast.error(data.message, toast_style);
    } else if (data.success) {
      toast.success(data.message, toast_style);
      setVisibleRescheduleId(null);
    }
  };

  const handleJoinCall = (googleMeetLink) => {
    window.open(googleMeetLink, "_blank");
  };

  const formatDateUsingMoment = (dateStr) => {
    return moment(dateStr).format("DD MMMM YYYY"); // '23 June 2024'
  };

  const getDayName = (date) => {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  };

  const getDayClassName = (date) => {
    const dayName = getDayName(date);
    if (!availableDays.includes(dayName)) {
      return "sunday-holiday";
    }
    return "";
  };

  const filterDates = (date) => {
    const dayName = getDayName(date);
    return !availableDays.includes(dayName);
  };

  const maxSelectableDate = new Date();
  maxSelectableDate.setDate(maxSelectableDate.getDate() + maxBookingDays);

  return (
    <>
      {loader ? (
        <NewLoader></NewLoader>
      ) : (
        <div className="requests-container">
          {upcomingSessions.length > 0 ? (
            upcomingSessions?.map((request) => (
              <div key={request._id} className="request-container">
                <div className="request-item1">
                  <div className="request-header-upcom">
                    <div className="profile-img"></div>
                    <p>
                      {request?.user_id?.name || request?.mentor_id?.name} |{" "}
                      <span>
                        {request?.user_id?.email || request?.mentor_id?.email}
                      </span>
                    </p>
                  </div>
                  <div className="request-actions">
                    {/* <div className={`action-item-res ${visibleRescheduleId === request._id ? 'active' : ''}`} onClick={() => toggleReschedule(request._id)}>
                <IoMdTimer className="iconSize" />
                <p>Reschedule</p>
              </div> */}
                    {/* <button className="action-button">
                <MdOutlineMessage className="iconSize" />
              </button> */}
                    <button className="action-button">
                      <RxCross2 className="iconSize" />
                    </button>
                    <button
                      className={`action-button ${
                        visibleDetailsId === request._id ? "active" : ""
                      }`}
                      onClick={() => toggleDetails(request._id)}
                    >
                      <FaInfo className="iconSize" />
                    </button>
                  </div>
                </div>

                {visibleDetailsId === request._id && (
                  <div className="details-section2">
                    <div className="details-container">
                      <div>
                        <p>
                          <span>{request?.session_id?.type} </span>|{" "}
                          <span>{request?.session_id?.duration} minutes</span>
                        </p>
                        <p>
                          {" "}
                          {formatDateUsingMoment(request?.date)} |{" "}
                          {request?.startTime}
                        </p>
                      </div>
                      <button
                        className="join-button"
                        onClick={() => handleJoinCall(request?.meeting_link)}
                      >
                        Join the call
                      </button>
                    </div>
                    <br></br>
                    <p className="description">{request?.agenda_insights}</p>
                  </div>
                )}

                {timeSlots.length > 0 && visibleRescheduleId === request._id && (
                  <div className="details-section1">
                    <div className="details-section-container1">
                      <div className="reschedule-section">
                        <div className="calendar-container">
                          <p>Select day</p>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="dd/MM/yyyy"
                            inline
                            dayClassName={getDayClassName}
                            filterDates={filterDates}
                            minDate={new Date()} // Set the start date to today
                            maxDate={maxSelectableDate} // Set the end date to 2 weeks from today
                          />
                        </div>
                        <div className="time-slot-container">
                          <p>Select time slots</p>
                          <div className="time-selector">
                            {timeSlots?.map((element, index) => (
                              <div
                                key={index}
                                className={`time-slot ${
                                  selectedTimeSlot ===
                                  `${element.startTime} - ${element.endTime}`
                                    ? "active"
                                    : ""
                                }`}
                                onClick={() => handleTimeSlotSelect(element)}
                              >
                                <p>{element?.startTime}</p>
                                <span> - </span>
                                <p>{element?.endTime}</p>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      <div className="buttons-container">
                        <button
                          className="cancel-button"
                          onClick={() => setVisibleRescheduleId(null)}
                        >
                          Cancel
                        </button>
                        <button
                          className="reschedule-button"
                          onClick={() => handleReschedule(request._id)}
                        >
                          Reschedule
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="noDataContainer">
              No upcoming schedule session as of now
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default Upcoming;
