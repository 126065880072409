import React, {useState} from "react";
import { BsArrowUpRight } from "react-icons/bs";
import "./UserInfoSlate.css";

const UserInfoSlate = ({ user_name, user_email , image_url ,componentFunction,  ...props}) => {
  const [hover, setHover] = useState(false);

  const mainClass = {
    backgroundColor: props?.backgroundColor || "#F7F7F7",
    height : props?.height || "60px",
  };

  const imageClass = {
    width: props?.imageSize || "40px",
    height: props?.imageSize || "40px",
  }
  return (
    <div className="request" style={mainClass} onMouseEnter={() => setHover(true) } onMouseLeave={() => setHover(false)} onClick={() => componentFunction()}>

      {image_url && <img src={image_url} alt="user" style={imageClass} className="profile-img" />}

      <div>
        {user_name}

        {user_email && (
          <span>
            {" "}
            | <span>{user_email}</span>
          </span>
        )}
      </div>
      { hover && <span class="material-symbols-outlined">
arrow_outward
</span>}
    </div>
  );
};

export default UserInfoSlate;
